import './CompanyProfileState.scss';
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Company from "../../../../data/company/Company";
import IconButton from "../../../../ui/icon-button/IconButton";
import {CompanyService} from "../../../../data/company/Company.service";
import {CompanyProfileState} from "../../../../data/company/CompanyProfileState";
import {useDispatch} from "react-redux";
import Spinner from "../../../../ui/spinner/Spinner";
import {companyActions} from "../../../../store/company";

export interface CompanyProfileStateProps {
    company: Company
}

const CompanyProfileStateEdit = (props: CompanyProfileStateProps) => {
    const dispatch = useDispatch();

    const company = props.company;
    const originMessage = company.profileState?.message ? company.profileState?.message : '';
    const [message, setMessage] = useState(originMessage);
    const [saving, setSaving] = useState(false);

    function onMessageChanged(event: any) {
        setMessage(event.target.value);
    }

    function onAdd() {
        setSaving(true);
        const state: CompanyProfileState = {
            message: message
        }
        CompanyService.updateProfileState(state).then(company => {
            dispatch(companyActions.setCompany(company));
            setSaving(false);
        })
    }

    function onCancel() {
        setMessage(originMessage);
    }

    return <div className={'me-company-profile-state'}>
        <div className={'me-flex-row'}>
            <Form.Label className={'me-flex-grow me-margin-md-left'}>
                {'Was läuft bei ' + company.company + ' ...'}
            </Form.Label>
            <div className={'me-max-character-indicator'}>{message?.length}/50</div>
        </div>
        <div className={'me-flex-row me-align-item-center me-align-item-start'}>
            <div className={'me-flex-column me-flex-grow me-margin-md-right'}>
                <Form.Control
                    id='message'
                    maxLength={50}
                    onChange={onMessageChanged}
                    value={message}
                    required>
                </Form.Control>
            </div>
            {!saving && <div className={'me-flex-row'}>
                <IconButton
                    className={'green-hover'}
                    disabled={message === originMessage}
                    iconName={'check'}
                    onClick={onAdd}/>
                <IconButton
                    className={'red-hover'}
                    disabled={message === originMessage}
                    marginLeft={5}
                    iconName={'xmark'}
                    onClick={onCancel}/>
            </div>}
            {saving && <Spinner/>}
        </div>
    </div>

}

export default CompanyProfileStateEdit;