import axiosInstance from "../../../AxiosInterceptor";
import {CompanyAbout, CompanyAboutItem} from "./CompanyAbout";

export class CompanyAboutService {

    public static async search(companyId: string): Promise<CompanyAbout | null> {
        const response = await axiosInstance.get(`companies/${companyId}/about/`);
        return this.mapAbout(response.data);
    }

    public static async update(about: CompanyAbout): Promise<void> {
        return await axiosInstance.post('/companies/about', about);
    }

    private static mapAbout(data: any): CompanyAbout | null {
        if (!data) {
            return null;
        }
        return {
            companyId: data.companyId,
            description: data.description,
            items: data.items.map(this.mapAboutItems)
        }
    }

    private static mapAboutItems(data: any): CompanyAboutItem {
        return {
            id: data.id,
            image: data.image,
            title: data.title,
            description: data.description
        }
    }


}