import axios from "axios";
import {LoginData} from "./LoginData";
import {host} from "../host.env";
import {SignupData} from "./SignupData";
import {Company, newCompany} from "../company/Company";
import {PasswordService} from "./Password.service";
import {Account} from "../account/Account";

export class AuthService {

    public static isLoggedIn() {
        return !!sessionStorage.getItem("token");
    }

    public static readAccount() {
        return sessionStorage.getItem('account');
    }

    public static isDevelopment() {
        return sessionStorage.getItem('env') === 'DEVELOPMENT';
    }

    public static accessToken(): string {
        return JSON.parse(sessionStorage.getItem('token') || '');
    }

    public static async signup(signup: SignupData): Promise<Account> {
        const response = await axios.post(host() + 'auth/account/register/company', signup, {withCredentials: true});
        if (response.data === PasswordService.REQUEST_FAILED_CODE_NOT_EQUAL) {
            throw new Error(PasswordService.REQUEST_FAILED_CODE_NOT_EQUAL);
        }
        sessionStorage.setItem("account", JSON.stringify(response.data.account));
        sessionStorage.setItem("token", JSON.stringify(response.data.token));
        return response.data.account;
    }

    public static async signupRequest(email: string) {
        try {
            const response = await axios.post(host() + 'auth/account/register/request', {email: email}, {withCredentials: true});
            if (response.data === PasswordService.EMAIL_SENT) {
                return PasswordService.EMAIL_SENT;
            }
            if (response.data === PasswordService.REQUEST_FAILED_EMAIL_ALREADY_IN_USE) {
                return PasswordService.REQUEST_FAILED_EMAIL_ALREADY_IN_USE;
            }
            return response.data
        } catch (e) {

        }
    }

    public static async login(login: LoginData): Promise<Account> {
        const response = await axios.post(host() + 'auth/account/login', login, {withCredentials: true});
        sessionStorage.setItem("account", JSON.stringify(response.data.account));
        sessionStorage.setItem("token", JSON.stringify(response.data.token));
        sessionStorage.setItem("env", response.data.env);
        return response.data.account;

    }

    public static async refresh(): Promise<string> {
        this.logout();
        const response = await axios.post(host() + 'auth/account/refresh', '', {withCredentials: true});
        let token = response.data.token;
        sessionStorage.setItem("account", JSON.stringify(response.data.account));
        sessionStorage.setItem("token", JSON.stringify(token));
        return token;
    }

    public static logout() {
        sessionStorage.removeItem("account");
        sessionStorage.removeItem("token");
    }
}