import './ChatPage.scss';

import Spinner from "../../ui/spinner/Spinner";
import IconButton from "../../ui/icon-button/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import React, {useState} from "react";
import ChatTile from "../../components/chat/tile/ChatTile";
import CreateChatForm from "../../components/chat/create/CreateChatForm";
import {chatsActions} from "../../store/chats";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChatCommentsOverview from "../../components/chat/comments/ChatCommentsOverview";
import {ChatService} from "../../data/chat/ChatService";
import {sortChats} from "../../data/chat/ChatUtil";

const ChatPage = () => {

    const dispatch = useDispatch();

    const chats = useSelector((state: RootState) => state.chats.chats);
    const loading = useSelector((state: RootState) => state.chats.loading);

    const [showMutation, setShowMutation] = useState(false);
    const [selectedChatId, setSelectedChatId] = useState('');
    const selectedChat = chats.find(t => t.chatId === selectedChatId);

    function refreshChats() {
        dispatch(chatsActions.loading(true));
        ChatService.search().then(chats => {
            dispatch(chatsActions.loading(false));
            dispatch(chatsActions.replaceAll(chats))
        });
    }

    function upateEmailNotification(chatId: string, active: boolean) {
        ChatService.updateEmailNotification(chatId, active).then(() => {
            ChatService.read(chatId).then(chat => {
                dispatch(chatsActions.replaceChat(chat));
            });
        });
    }

    return (
        <>
            {loading && <Spinner/>}
            {!loading && <div className='me-page-container'>
                <div className='me-threads-page-menu'>
                    <div className={'me-flex-grow'}>
                        <IconButton iconName={'add'} onClick={() => setShowMutation(true)}/>
                    </div>
                </div>
                <div className='me-thread-page-list-container'>
                    {chats
                        .map(c => c)
                        .sort(sortChats())
                        .map(chat => (
                            <ChatTile
                                key={chat.chatId}
                                chat={chat}
                                onEmailNotification={(active) => upateEmailNotification(chat.chatId, active)}
                                onTileClicked={chatId => setSelectedChatId(chatId)}
                                onDeleted={refreshChats}
                            />
                        ))}
                </div>
            </div>}
            <CreateChatForm
                showModal={showMutation}
                onSave={() => {
                    setShowMutation(false);
                    refreshChats();
                }}
                onCancel={() => setShowMutation(false)}/>
            <Modal show={!!selectedChat}
                   backdrop="static"
                   size={'lg'}
                   onHide={() => setSelectedChatId('')}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={'me-open-modal-button-modal-header'}>
                            <FontAwesomeIcon icon={'comments'}></FontAwesomeIcon>
                            {selectedChat &&
                                <div>{selectedChat.topic ? selectedChat.topic : selectedChat.ownerName}</div>}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedChat && <ChatCommentsOverview chat={selectedChat}/>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => setSelectedChatId('')}>Schliessen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default ChatPage;