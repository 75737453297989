import './Profil.scss'

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {settingActions} from "../../store/settings";
import LeftFlypanel from "../../ui/panel/LeftFlypanel";
import CompanyProfil from "./company/CompanyProfil";
import GemeindeProfil from "./gemeinde/GemeindeProfil";

const Profil = () => {

    const dispatch = useDispatch();
    const showSetting: boolean = useSelector((state: RootState) => state.setting.show)
    const company = useSelector((state: RootState) => state.company?.data);
    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data);

    function onClosePanelHandler() {
        dispatch(settingActions.hideSettings());
    }


    const settingWidth = (window.innerWidth > 500 ? 500 : window.innerWidth);

    return (
        <LeftFlypanel
            title='Profil' onClose={onClosePanelHandler} width={settingWidth + 'px'}
            show={showSetting}>
            <div className='me-profile-container'>
                {company && <CompanyProfil/>}
                {gemeinde && <GemeindeProfil/>}
            </div>
        </LeftFlypanel>
    )

}

export default Profil;