import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Chat} from "../data/chat/Chat";


export interface ChatsState {
    loading: boolean;
    chats: Chat[];
}

const initialState: ChatsState = {
    loading: false,
    chats: [],
}

const chatsSlice = createSlice({
    name: 'chats',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<Chat[]>) {
            state.chats = action.payload;
        },
        replaceChat(state, action: PayloadAction<Chat>) {
            const chats = state.chats.filter(c => c.chatId !== action.payload.chatId);
            chats.push(action.payload);
            state.chats = chats;
        },
    }
})

export const chatsActions = chatsSlice.actions;
export default chatsSlice.reducer;