export class WeblinkUtil {

    static concatWithProtocol(url: string): string {
        if (!url) {
            return '';
        }
        if (!url.startsWith('http')) {
            return 'https://' + url;
        }
        return url;
    }

}