import './EventCommentsOverview.scss';
import {Event} from "../../../data/event/Event";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {DateUtil} from "../../../data/DateUtil";
import {EventService} from "../../../data/event/Event.service"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReplyEventComment from "./reply/ReplyEventComment";
import {eventsActions} from "../../../store/events";
import AddEventComment from "./add/AddEventComment";

export interface EventCommentsOverviewProps {
    event: Event;
}

const EventCommentsOverview = (props: EventCommentsOverviewProps) => {


    const dispatch = useDispatch();
    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;
    const event = props.event;
    const allComments = props.event.comments;
    const mainComments = allComments.filter(c => !c.parentCommentId);

    function Replays(props: any) {
        const replayComments = allComments.filter(c => c.parentCommentId === props.parentId);
        return (
            <div>
                {replayComments.map(replayComment => (
                    <div key={replayComment.commentId}>
                        <div
                            className={`me-event-comment-overview-message-container replay`}>
                            <FontAwesomeIcon className={'me-event-comment-reply-icon'} icon={'reply'}></FontAwesomeIcon>
                            <div className={'me-comment-message'}>{replayComment.message}</div>
                            <div className={'me-comment-info'}>
                                <div className={'me-comment-user-name'}>{replayComment.userName}</div>
                                <div
                                    className={'me-comment-created'}>{DateUtil.displayShort(replayComment.created)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    function refreshEvent() {
        EventService.readEvent(event.eventId)
            .then(event => {
                dispatch(eventsActions.replaceOne(event));
            });
    }

    return (
        <div className={'me-event-comment-overview-container'}>
            <div className={'me-event-comment-overview-list'}>
                {mainComments.map(comment => (
                    <div className={'me-event-comment-overview-list-item'} key={comment.commentId}>
                        <div
                            className={`me-event-comment-overview-message-container ${comment.userId === companyId ? 'company-message' : ''}`}>
                            <div className={'me-comment-message'}>{comment.message}</div>
                            <div className={'me-comment-info'}>
                                <div className={'me-comment-user-name'}>{comment.userName}</div>
                                <div className={'me-comment-created'}>{DateUtil.displayShort(comment.created)}</div>
                            </div>
                        </div>
                        <Replays parentId={comment.commentId}/>
                        {comment.userId !== companyId &&
                            <ReplyEventComment
                                parentCommentId={comment.commentId}
                                companyId={event.companyId}
                                eventId={event.eventId}
                                reply={refreshEvent}
                            />
                        }

                    </div>
                ))}
            </div>
            <div className={'me-add-event-comment-container'}>
                <AddEventComment
                    companyId={event.companyId}
                    eventId={event.eventId}
                    add={refreshEvent}/>
            </div>
        </div>
    )
}

export default EventCommentsOverview;