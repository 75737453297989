import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Gemeinde} from "../data/gemeinde/Gemeinde";

export interface GemdeindState {
    data: Gemeinde | null;
    loading: boolean;
}

const initialState: GemdeindState = {
    data: null,
    loading: false,
}

const gemeindeSlice = createSlice({
    name: 'gemeinde',
    initialState: initialState,
    reducers: {
        setGemeinde(state, action: PayloadAction<Gemeinde>) {
            state.data = action.payload;
        },
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    }
})

export const gemeindeActions = gemeindeSlice.actions;
export default gemeindeSlice.reducer;