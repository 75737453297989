import './EditCompanyTeamMemberPopup.scss';
import {Alert, Button, Form} from "react-bootstrap";
import ImageSelector from "../../../../ui/image-selector/ImageSelector";
import React, {useEffect, useState} from "react";
import {CompanyTeamMember} from "../../../../data/company/team/CompanyTeam";

export interface EditCompanyTeamMemberProps {
    editMember?: CompanyTeamMember | null;
    close: () => void;
    addMember: (item: CompanyTeamMember) => void;
}

const EditCompanyTeamMemberPopup = (props: EditCompanyTeamMemberProps) => {

    const [error, setError] = useState('');
    const [role, setRole] = useState<string>('');
    const [firstName, setFirstname] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (props.editMember) {
            setFirstname(props.editMember.firstname);
            setName(props.editMember.name);
            setRole(props.editMember.role);
            setDescription(props.editMember.description);
            setImage(props.editMember.image);
        }
    }, [props.editMember])

    function onFirstNameChanged(event: any) {
        setError('');
        setFirstname(event.target.value)
    }

    function onNameChanged(event: any) {
        setError('');
        setName(event.target.value)
    }

    function onRoleChanged(event: any) {
        setError('');
        setRole(event.target.value)
    }

    function onDescriptionChanged(event: any) {
        setError('');
        setDescription(event.target.value)
    }

    function onImageChanged(image: string) {
        setError('');
        setImage(image);
    }

    function save() {
        if (!image) {
            setError("Ein Bild ist erforderlich.");
            return;
        }
        const id = props.editMember ? props.editMember.id : new Date().getTime().toString(36);
        props.addMember({
            id: id,
            firstname: firstName,
            name: name,
            role: role,
            description: description,
            image: image
        })
    }

    return (
        <>
            <div className={'me-company-edit-team-member-backdrop'}/>
            <div className={'me-company-edit-team-member-popup'}>
                <Form>
                    <Form.Group className="mb-3">
                        <ImageSelector
                            defaultImage={image}
                            imageMaxSizeMB={0.4}
                            imageSelected={onImageChanged}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Control
                            placeholder={'Funktionbeschreibung'}
                            value={role}
                            maxLength={50}
                            onChange={onRoleChanged}
                            required>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{role?.length}/50</div>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Control
                            placeholder={'Vorname'}
                            value={firstName}
                            maxLength={50}
                            onChange={onFirstNameChanged}
                            required>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{firstName?.length}/50</div>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Control
                            placeholder={'Name'}
                            value={name}
                            maxLength={50}
                            onChange={onNameChanged}
                            required>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{name?.length}/50</div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea" rows={4}
                            placeholder={'Beschreibung'}
                            value={description}
                            maxLength={500}
                            onChange={onDescriptionChanged}/>
                        <div className={'me-max-character-indicator'}>{description?.length}/500</div>
                    </Form.Group>
                </Form>
                {error && <Alert className={`me-add-company-member-alert`} variant='danger'>{error}</Alert>}
                <div className={'me-company-edit-team-member-popup-footer'}>
                    <Button variant="secondary" onClick={props.close}> Abbrechen </Button>
                    <Button variant="primary" onClick={save}> Übernehmen </Button>
                </div>
            </div>
        </>
    )

}

export default EditCompanyTeamMemberPopup;