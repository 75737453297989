import CompanyProfilMenu from "./CompanyProfilMenu";
import CompanyProfileStateEdit from "./state/CompanyProfileStateEdit";
import AccountLogo from "../profil-logo/AccountLogo";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import Company from "../../../data/company/Company";
import {CompanyService} from "../../../data/company/Company.service";
import {companyActions} from "../../../store/company";

const CompanyProfil = () => {

    const dispatch = useDispatch();
    const company = useSelector((state: RootState) => state.company?.data) as Company;

    function companyImageChanged(image: string) {
        const companyProfilUpdate = {
            id: company?.id as string,
            company: company?.company as string,
            adresse: company?.adresse as any,
            image: image,
            email: company?.email as string,
            telefon: company?.telefon as string,
            specialOpeningHours: company?.specialOpeningHours as any
        }

        CompanyService.update(companyProfilUpdate).then(() => {
            CompanyService.read(company?.id as string).then(company => {
                dispatch(companyActions.setCompany(company));
            })
        })
    }

    return (
        <>
            <CompanyProfileStateEdit company={company}/>
            <AccountLogo image={company?.image as string} imageChanged={companyImageChanged}/>
            <CompanyProfilMenu/>
        </>
    )

}

export default CompanyProfil;