import './GemeindeAddressEdit.scss'

import {Alert, Button, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useEffect, useState} from "react";
import GemeindeAddressForm from "./form/GemeindeAddressForm";
import {Address} from "../../../../data/address/Address";
import ProfileFormGroup from "../../profil-form-group/ProfileFormGroup";
import GemeindeLocationsForm from "./form/GemeindeLocationsForm";
import {Gemeinde} from "../../../../data/gemeinde/Gemeinde";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {GemeindeService} from "../../../../data/gemeinde/Gemeinde.service";
import {gemeindeActions} from "../../../../store/gemeinde";
import {areArraysEqual} from "../../../../data/base/ObjectUtil";

export interface GemeindeLocationEditProps {
    onSaved: () => void;
    onCancel: () => void;
}

const GemeindeAddressEdit = (props: GemeindeLocationEditProps) => {

    const dispatch = useDispatch();
    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data) as Gemeinde;

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [changes, setChanges] = useState(false);
    const [address, setAddress] = useState<Address>(gemeinde.address);
    const [locations, setLocations] = useState(gemeinde.locations);


    useEffect(() => {
        setChanges(hasChanges);
    }, [address, locations]);

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setError('');
    }

    function save() {
        if (!validData()) {
            return;
        }
        setError('');
        setSaving(true);
        const updateAddress = {
            address: address,
            locations: locations
        }
        GemeindeService.updateAddress(gemeinde.id, updateAddress)
            .then(() => {
                dispatch(gemeindeActions.loading(true));
                GemeindeService.read(gemeinde.id).then(gemeinde => {
                    dispatch(gemeindeActions.loading(false));
                    dispatch(gemeindeActions.setGemeinde(gemeinde))
                    props.onSaved();
                });
            })
            .catch((error) => {
                    setSaving(false);
                    setError(error.message);
                }
            );
    }

    function hasChanges(): boolean {
        const addressChanged =
            gemeinde.address?.street !== address?.street ||
            gemeinde.address?.streetNumber !== address?.streetNumber ||
            gemeinde.address?.zip !== address?.zip ||
            gemeinde.address?.city !== address?.city ||
            gemeinde.address?.coordinate?.latitude !== address?.coordinate?.latitude ||
            gemeinde.address?.coordinate?.longitude !== address?.coordinate?.longitude;
        const locationsEquals = areArraysEqual(gemeinde.locations, locations);
        return addressChanged || !locationsEquals;
    }

    function validData(): boolean {
        if (!address) {
            setError('Addresse ist erforderlich');
            return false;
        }
        if (!address.street) {
            setError('Strasse ist erforderlich');
            return false;
        }
        if (!address.zip) {
            setError('Plz ist erforderlich');
            return false;
        }
        if (!address.city) {
            setError('Ort ist erforderlich');
            return false;
        }
        if (!address.coordinate?.latitude) {
            setError('Latitude ist erforderlich');
            return false;
        }
        if (!address.coordinate?.longitude) {
            setError('Longitude ist erforderlich');
            return false;
        }
        if (isNaN(parseFloat(address.coordinate.latitude))) {
            setError('Latitude hat falsches Format');
            return false;
        }
        if (isNaN(parseFloat(address.coordinate.longitude))) {
            setError('Longitude hat falsches Format');
            return false;
        }
        return true;
    }

    return <>
        <Modal show={true}
               backdrop="static"
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Adresse</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'me-margin-md'}>
                    <GemeindeAddressForm
                        address={gemeinde.address}
                        onChanged={setAddress}/>
                    <ProfileFormGroup label={'Kartenpunkte (optional)'} icon={'location'}/>
                    <GemeindeLocationsForm
                        locations={locations}
                        onChanged={setLocations}
                    />
                </div>
                {error &&
                    <Alert className='settings-error' variant='danger'>{error}</Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button disabled={!changes} variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default GemeindeAddressEdit;