import './ClientCard.scss';
import {Client} from "../../../data/client/Client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "../../../ui/spinner/Spinner";
import IconButton from "../../../ui/icon-button/IconButton";
import {DateUtil} from "../../../data/DateUtil";
import {TelefonUtil} from "../../../data/TelefonUtil";
import {ClientUtil} from "../../../data/client/ClientUtil";
import {SubscriptionService} from "../../../data/subscription/Subscription.service";
import {subscriptionActions} from "../../../store/subscriptions";
import {applicationActions} from "../../../store/application";
import {Alert, CloseButton} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import Image from "react-bootstrap/Image";

export interface ClientDisplayProps {
    client: Client;
    isContact: boolean;
    onClose: () => void;
}

const ClientCard = (props: ClientDisplayProps) => {

    const dispatch = useDispatch();

    const [fullProfileImage, setFullProfileImage] = useState(false);
    const [clientIdUpdating, setClientIdUpdating] = useState('');
    const [error, setError] = useState('');
    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;

    const client = props.client;
    const isContact = props.isContact;

    const birthday = client.contact.birthday ? DateUtil.displayLongNoTime(client.contact.birthday) : '-';
    const phone = client.contact.telefon ? TelefonUtil.formatString(client.contact.telefon) : '-';
    const home = ClientUtil.concatHome(client.contact);
    const email = client.email;


    function isUpdating(clientId: string) {
        return clientId === clientIdUpdating;
    }

    function onClickCheckContact(e: any, clientId: string, isContact: boolean) {
        e.stopPropagation();
        markContact(clientId, isContact);
    }


    function markContact(clientId: string, isContact: boolean): void {
        setError('');
        setClientIdUpdating(clientId);
        SubscriptionService.markContact(clientId, companyId, isContact)
            .then(() => {
                setClientIdUpdating('');
                dispatch(subscriptionActions.markContact({
                        clientId: clientId,
                        isContact: isContact
                    }
                ))
                dispatch(applicationActions.refreshDashboard());
            }).catch(e => {
            setClientIdUpdating('')
            setError(e)
        });
    }

    return (
        <div className={'me-client-card-container'}>
            {fullProfileImage && <div className={`me-client-card-profile-image-full`}>
                <Image src={client.profileImage}/>
                <CloseButton className={'me-client-card-close'}
                             onClick={() => setFullProfileImage(false)}></CloseButton>
            </div>}
            <div className={'me-client-card-header-container'}>
                {!client.profileImage && <div className={'me-client-card-avatar'}>
                    <FontAwesomeIcon icon={'user'}/>
                </div>}
                {client.profileImage &&
                    <div className={'me-client-card-profile-image'} onClick={() => setFullProfileImage(true)}>
                        {client.profileImage && <Image src={client.profileImage}/>}
                    </div>}
                <div className={'me-client-card-header'}>
                    <div className={'me-client-card-name'}>{client.firstname} {client.name}</div>
                    <div>{home}</div>
                </div>
                <CloseButton className={'me-client-card-close'} onClick={props.onClose}></CloseButton>
            </div>

            <div className={'me-client-card-data'}>
                <div className={'me-client-card-value'}>
                    <FontAwesomeIcon icon={'birthday-cake'}/>
                    <div>{birthday}</div>
                </div>
                <div className={'me-client-card-value'}>
                    <FontAwesomeIcon icon={'phone'}/>
                    <div>{phone}</div>
                </div>
                <div className={'me-client-card-value'}>
                    <FontAwesomeIcon icon={'envelope'}/>
                    <a href={"mailto:" + email}>{email}</a>
                </div>
            </div>
            <div className={'me-client-card-footer'}>
                {isUpdating(client.id) &&
                    <Spinner small={true}></Spinner>
                }
                {!isUpdating(client.id) && <>
                    {isContact && <IconButton
                        iconName={'user'}
                        buttonLabel={'Kontakt'}
                        onClick={(e) => onClickCheckContact(e, client.id, false)}/>}
                    {!isContact && <IconButton
                        iconName={'user'}
                        buttonLabel={'Follower'}
                        onClick={(e) => onClickCheckContact(e, client.id, true)}/>}
                </>}
            </div>
            {error && <Alert variant={"danger"}>{error}</Alert>}
        </div>
    )

}

export default ClientCard;