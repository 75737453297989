import './SurveyResultQuestion.scss';


import {SurveyResultQuestion as SurveyResultQuestionModel} from "../../../data/survey/Survey";
import SurveyResultAnswer from "./SurveyResultAnswer";


export interface SurveyResultQuestionProps {
    resultQuestion: SurveyResultQuestionModel;
    selected: boolean; // used for start animation each time new
}

const SurveyResultQuestion = (props: SurveyResultQuestionProps) => {

    const resultQuestion = props.resultQuestion;
    const highestValue = Math.max(...props.resultQuestion.answersResults.map(a => a.count));

    return (
        <div className='me-survey-result-question'>
            <div className='me-survey-result-question-title'>{resultQuestion.question}</div>
            {
                resultQuestion.answersResults.map((resultAnswer, index) => {
                    return (
                        props.selected && <SurveyResultAnswer
                            key={index}
                            highestValue={highestValue}
                            resultAnswer={resultAnswer}/>
                    )
                })
            }
        </div>
    )

}

export default SurveyResultQuestion;