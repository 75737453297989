import './Confirm.scss';
import ReactDom from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {confirmActions} from "../../store/confirm";
import {Button} from "react-bootstrap";

const Confirm = () => {

    const show = useSelector((state: RootState) => state.confirm.show);
    const text = useSelector((state: RootState) => state.confirm.data.text);
    const overlayRoot: HTMLElement = document.getElementById('overlay-root') as HTMLElement;
    const dispatch = useDispatch();

    function onCancelClick() {
        dispatch(confirmActions.cancel())
    }

    function onOkClick() {
        dispatch(confirmActions.ok())
    }

    return (
        ReactDom.createPortal(
            <div className={`confirm ${show ? 'show' : ''}`}>
                <div className={`confirm__content`}>
                    <div className={`confirm__text`}>{text}</div>
                    <div className={'confirm__buttons'}>
                        <Button onClick={onOkClick}>Ok</Button>
                        <Button onClick={onCancelClick}>Abbrechen</Button>
                    </div>
                </div>
            </div>, overlayRoot)
    )
}

export default Confirm;