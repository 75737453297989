import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {Gemeinde, GemeindeInfo} from "../../../../data/gemeinde/Gemeinde";
import React, {useEffect, useRef, useState} from "react";
import {GemeindeService} from "../../../../data/gemeinde/Gemeinde.service";
import {gemeindeActions} from "../../../../store/gemeinde";
import {Alert, Button, Col, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import Form from "react-bootstrap/Form";

export interface GemeindeInfoEditProps {
    onSaved: () => void;
    onCancel: () => void;
}

const GemeindeInfoEdit = (props: GemeindeInfoEditProps) => {

    const formRef = useRef(null);
    const dispatch = useDispatch();
    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data) as Gemeinde;

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [changes, setChanges] = useState(false);

    const [population, setPopulation] = useState('');
    const [landAreaSquareKilometer, setLandAreaSquareKilometer] = useState('');
    const [peoplePerSquareKilometer, setPeoplePerSquareKilometer] = useState('');
    const [foreignPopulationPercentage, setForeignPopulationPercentage] = useState('');

    useEffect(() => {
        if (gemeinde.info) {
            const info = gemeinde.info;
            setPopulation(numberAsString(info.population));
            setLandAreaSquareKilometer(numberAsString(info.landAreaSquareKilometer));
            setPeoplePerSquareKilometer(numberAsString(info.peoplePerSquareKilometer));
            setForeignPopulationPercentage(numberAsString(info.foreignPopulationPercentage));
        }
    }, []);

    useEffect(() => {
        setChanges(hasChanges);
    }, [population, landAreaSquareKilometer, peoplePerSquareKilometer, foreignPopulationPercentage]);

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setError('');
    }

    function save() {
        const form = formRef.current as any;
        setError('');
        setSaving(true);

        if (form.checkValidity() === false) {
            setError('Bitte alle Mussfelder ausfüllen');
            setSaving(false);
            return;
        }

        function mapGemeindeInfo(): GemeindeInfo {
            return {
                population: population ? parseFloat(population) : -1,
                landAreaSquareKilometer: landAreaSquareKilometer ? parseFloat(landAreaSquareKilometer) : -1,
                peoplePerSquareKilometer: peoplePerSquareKilometer ? parseFloat(peoplePerSquareKilometer) : -1,
                foreignPopulationPercentage: foreignPopulationPercentage ? parseFloat(foreignPopulationPercentage) : -1
            }
        }

        const gemeindeInfo = mapGemeindeInfo();
        GemeindeService.updateInfo(gemeinde.id, gemeindeInfo)
            .then(() => {
                dispatch(gemeindeActions.loading(true));
                GemeindeService.read(gemeinde.id).then(gemeinde => {
                    dispatch(gemeindeActions.loading(false));
                    dispatch(gemeindeActions.setGemeinde(gemeinde))
                    props.onSaved();
                });
            })
            .catch((error) => {
                    setSaving(false);
                    setError(error.message);
                }
            );
    }

    function hasChanges(): boolean {
        return hasNumberChanged(gemeinde.info?.population, population) ||
            hasNumberChanged(gemeinde.info?.landAreaSquareKilometer, landAreaSquareKilometer) ||
            hasNumberChanged(gemeinde.info?.peoplePerSquareKilometer, peoplePerSquareKilometer) ||
            hasNumberChanged(gemeinde.info?.foreignPopulationPercentage, foreignPopulationPercentage)
    }

    function hasNumberChanged(origin: number, value: string) {
        let valueAsNumber = parseFloat(value);
        if (isNaN(valueAsNumber)) {
            return origin === -1;
        }
        return origin !== valueAsNumber;
    }

    function numberAsString(number: Number): string {
        return number === -1 ? '' : String(number)
    }

    function createGemeindeInfoForm() {
        return <div className={'me-flex-column'}>
            <Form ref={formRef} className={'me-setting-general-form'}>
                <Form.Label>Einwohner*</Form.Label>
                <Form.Group className="mb-3">
                    <Form.Control
                        id='name'
                        type={'number'}
                        onChange={(event) => setPopulation(event.target.value)}
                        value={population}
                        required>
                    </Form.Control>
                </Form.Group>
                <Form.Label>Fläche</Form.Label>
                <Form.Group className="mb-3">
                    <Form.Control
                        id='name'
                        type={'number'}
                        onChange={(event) => setLandAreaSquareKilometer(event.target.value)}
                        value={landAreaSquareKilometer}>
                    </Form.Control>
                </Form.Group>
                <Form.Label>Einwohnerdichte</Form.Label>
                <Form.Group as={Col}>
                    <Form.Control
                        id='name'
                        type={'number'}
                        onChange={(event) => setPeoplePerSquareKilometer(event.target.value)}
                        value={peoplePerSquareKilometer}>
                    </Form.Control>
                </Form.Group>
                <Form.Label>Ausländeranteil %</Form.Label>
                <Form.Group as={Col}>
                    <Form.Control
                        id='name'
                        type={'number'}
                        onChange={(event) => setForeignPopulationPercentage(event.target.value)}
                        value={foreignPopulationPercentage}>
                    </Form.Control>
                </Form.Group>
            </Form>
            {error && <Alert className='settings-error' variant='danger'>{error}</Alert>}
        </div>
    }

    return <>
        <Modal show={true}
               backdrop="static"
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Informationen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createGemeindeInfoForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button disabled={!changes} variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default GemeindeInfoEdit;