import {AddEvent, emptyEventLayout, Event, EventAction, EventActionType} from "./Event";
import {axiosInstance} from "../../AxiosInterceptor";

export class EventService {

    public static async readEvent(eventId: string): Promise<Event> {
        let response = await axiosInstance.get(`events/${eventId}`);
        return EventService.mapEvent(response.data);
    }

    public static async addEvent(addEvent: AddEvent): Promise<void> {
        return await axiosInstance.post('events/company/', addEvent);
    }

    public static async updateEvent(eventId: string, event: Event): Promise<void> {
        return await axiosInstance.put('events/'+eventId, event);
    }

    public static async searchEvents(companyId: string): Promise<Event[]> {
        let response = await axiosInstance.get(`events/owner/${companyId}`);
        return response.data.map(EventService.mapEvent);
    }

    public static async activate(eventId: string) {
        return await axiosInstance.post(`events/${eventId}/activate`);
    }

    public static async deleteEvent(eventId: string) {
        return await axiosInstance.delete(`events/${eventId}`);
    }

    public static async addComment(companyId: string, eventId: string, message: string, parentCommentId: string) {
        return await axiosInstance.post(`events/${companyId}/events/${eventId}/company/comment`, {
            message: message,
            parentCommentId: parentCommentId
        });
    }

    private static mapEvent(data: any): Event {
        const layout = data.layout ? EventService.mapLayout(data.layout) : emptyEventLayout();

        return {
            eventId: data.eventId,
            companyId: data.companyId,
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            visibility: data.visibility,
            state: data.state,
            dates: data.dates,
            created: data.created ? data.created : '',
            invitationList: data.invitationList,
            participants: data.participants,
            comments: data.comments,
            layout: layout,
            action: EventService.mapAction(data.action)
        }
    }


    private static mapLayout(data: any) {
        return {
            image: data.image,
            imagePosition: data.imagePosition,
            backgroundColor: data.backgroundColor,
            showTitle: data.showTitle,
            titleColor: data.titleColor,
            titleFontSize: data.titleFontSize,
            showSubtitle: data.showSubtitle,
            subtitleColor: data.subtitleColor,
            subtitleFontSize: data.subtitleFontSize,
            showDates: data.showDates,
            datesColor: data.startdateColor,
            datesFontSize: data.datesFontSize,
        }
    }

    private static mapAction(data: any): EventAction {
        return {
            type: data.type as EventActionType,
            externalLink: data.externalLink,
            externalLinkLabelCode: data.externalLinkLabelCode
        }
    }
}