import './EventLayoutPreview.scss'
import React from "react";
import ImageSelector from "../../../../ui/image-selector/ImageSelector";
import {DateUtil} from "../../../../data/DateUtil";
import {ImagePosition} from "../../../../data/event/Event";
import {DateTime} from "../../../../data/base/DateTime";

interface EventLayoutProps {
    defaultImage?: string;
    imagePosition: ImagePosition;
    backgroundColor: string;
    title: string;
    titleColor: string;
    titleFontSize: string;
    subtitle: string;
    subtitleColor: string;
    subtitleFontSize: string;
    datesTimes: DateTime[];
    startdateColor: string;
    startdateFontSize: string;
    imageChanged: (image: string) => (void);
    editDisabled?: boolean;
}

const EventLayoutPreview = (props: EventLayoutProps) => {

    const titleStyle = {
        fontSize: props.titleFontSize + 'px',
        color: props.titleColor
    }

    const subtitleStyle = {
        fontSize: props.subtitleFontSize + 'px',
        color: props.subtitleColor
    }

    const startdateStyle = {
        fontSize: props.startdateFontSize + 'px',
        color: props.startdateColor
    }

    const starttimeStyle = {
        fontSize: (Number(props.startdateFontSize) - 2) + 'px',
        color: props.startdateColor
    }


    const backgroundStyle = {
        backgroundColor: props.imagePosition === ImagePosition.COVER ? '' : props.backgroundColor
    }

    function onImageChanged(image: string) {
        props.imageChanged(image);
    }

    return (
        <div style={backgroundStyle} className={'me-event-layout-preview-container'}>
            <div className={'me-event-layout-preview-image ' + props.imagePosition}>
                <ImageSelector
                    defaultImage={props.defaultImage}
                    imageMaxSizeMB={0.8}
                    imageSelected={onImageChanged}
                    editDisabled={props.editDisabled}
                />
            </div>
            <div className={'me-event-layout-overlay-data ' + props.imagePosition}>
                <div className={'me-event-layout-overlay-title ' + props.imagePosition}
                     style={titleStyle}>{props.title}</div>
                <div className={'me-event-layout-overlay-subtitle ' + props.imagePosition}
                     style={subtitleStyle}>{props.subtitle}</div>
                <div className={'me-event-layout-overlay-dates'}>
                    {
                        props.datesTimes.map(dateTime => {
                            return (
                                <div key={dateTime.date} className={'me-event-layout-overlay-date'}>
                                    <div className={'date'}
                                         style={startdateStyle}>{DateUtil.displayDateFromDateTime(dateTime)}
                                    </div>
                                    <div className={'time'}
                                         style={starttimeStyle}>{DateUtil.displayTimeFromDateTime(dateTime)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}


export default EventLayoutPreview;