import axiosInstance from "../../AxiosInterceptor";
import {AddChat, Chat} from "./Chat";


export class ChatService {

    public static async read(chatId: string): Promise<Chat> {
        let response = await axiosInstance.get(`chats/${chatId}`);
        return ChatService.mapChat(response.data);
    }

    public static async search(): Promise<Chat[]> {
        let response = await axiosInstance.get(`chats/all/company`);
        return response.data.map(ChatService.mapChat);
    }

    public static add(addChat: AddChat): Promise<void> {
        return axiosInstance.post('chats/company', addChat);
    }

    public static updateEmailNotification(chatId: string, active: boolean): Promise<void> {
        return axiosInstance.post(`chats/${chatId}/notifications/company`, {email: active})
    }

    public static async delete(chatId: string) {
        return await axiosInstance.delete(`chats/${chatId}`);
    }

    public static async addComment(chatId: string, message: string, parentCommentId: string) {
        return await axiosInstance.post(`/chats/${chatId}/comments/company`, {
            message: message,
            parentCommentId: parentCommentId
        });
    }

    static mapChat(data: any): Chat {
        return {
            chatId: data.chatId,
            type: data.type,
            ownerId: data.ownerId,
            ownerName: data.ownerName,
            ownerPartnerType: data.ownerPartnerType,
            ownerNotificationType: data.ownerNotificationType,
            directPartnerId: data.directPartnerId,
            directPartnerName: data.directPartnerName,
            directPartnerType: data.directPartnerType,
            directPartnerNotificationType: data.directPartnerNotificationType,
            topic: data.topic,
            visibility: data.visibility,
            participants: data.participants,
            lastComment: data.lastComment,
            comments: data.comments,
            created: data.created
        }
    }

}