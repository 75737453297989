import './ClientModal.scss';

import {Modal} from "react-bootstrap";
import React from "react";
import {Client} from "../../../data/client/Client";
import ClientCard from "../card/ClientCard";

export interface ClientModal {
    client: Client | null;
    isContact: boolean;
    onCancel: () => void;
}

const ClientModal = (props: ClientModal) => {

    function close() {
        props.onCancel()
    }

    return (
        <Modal show={!!props.client}

               className={'client-modal'}
               backdrop={true}
               onEscapeKeyDown={props.onCancel}
               onHide={() => close()}
               centered>
            <Modal.Body>
                {props.client &&<ClientCard
                    isContact={props.isContact}
                    onClose={close}
                    client={props.client}/>
                }
            </Modal.Body>
        </Modal>
    )

}

export default ClientModal;