import './EditCompanyTeamMemeberRow.scss';

import React from "react";
import Image from "react-bootstrap/Image";
import IconButton from "../../../../ui/icon-button/IconButton";
import {CompanyTeamMember} from "../../../../data/company/team/CompanyTeam";

export interface EditCompanyTeamMemberRowProps {
    memeber: CompanyTeamMember
    number: number;
    onRemove: (item: CompanyTeamMember) => void;
    onMoveUp: (item: CompanyTeamMember) => void;
    onClick: (item: CompanyTeamMember) => void;
}

const EditCompanyTeamMemeberRow = (props: EditCompanyTeamMemberRowProps) => {

    const member = props.memeber;
    const memberName = member.firstname + ' ' + member.name;

    function onRemove(e: any) {
        e.stopPropagation();
        props.onRemove(props.memeber);
    }

    function onMoveUp(e: any) {
        e.stopPropagation();
        props.onMoveUp(props.memeber);
    }

    return <>
        <div className={'me-edit-company-team-member-row'}>
            <div className={'me-edit-company-team-member-row-portrait'} onClick={() => props.onClick(props.memeber)}>
                <Image src={member.image} alt=''/>
                <div className={'me-edit-company-team-member-number'}>{props.number}</div>
                <div className={'me-edit-company-team-member-text-container'}>
                    {memberName && <div>{memberName}</div>} {(member.role) &&
                    <div className={'me-edit-company-team-member-role'}>{member.role}</div>}
                </div>
            </div>
            <div className={'me-edit-company-team-member-row-actions'}>
                <IconButton iconName={'arrow-up'} onClick={onMoveUp}/>
                <IconButton iconName={'trash-can'} className={'red-hover'} onClick={onRemove}/>
            </div>
        </div>
    </>

}

export default EditCompanyTeamMemeberRow;