import './EventLayoutDesigner.scss'

import React, {useEffect, useState} from "react";
import EventLayoutPreview from "./preview/EventLayoutPreview";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventLayout, ImagePosition} from "../../../data/event/Event";
import {DateTime} from "../../../data/base/DateTime";


interface EventLayoutDesignerProps {
    title: string;
    subtitle: string;
    dateTimes: DateTime[];
    defaultLayout?: EventLayout;
    layoutChanged: (eventLayout: EventLayout) => void;
}

const EventLayoutDesigner = (props: EventLayoutDesignerProps) => {

    const [layoutImage, setLayoutImage] = useState('');
    const [layoutImagePosition, setLayoutImagePosition] = useState(ImagePosition.COVER)
    const [backgroundColor, setBackgroundColor] = useState('#f0f0f0');
    const [showTitle, setShowTitle] = useState(true);
    const [titleColor, setTitleColor] = useState('#000000');
    const [titleFontSize, setTitleSize] = useState('20');
    const [showSubtitle, setShowSubtitle] = useState(true);
    const [subtitleColor, setSubtitleColor] = useState('#000000');
    const [subtitleFontSize, setSubtitleSize] = useState('14');
    const [showStartDate, setShowStartDate] = useState(true);
    const [startdateColor, setStartdateColor] = useState('#000000');
    const [startdateFontSize, setStartdateSize] = useState('14');

    useEffect(() => {
        if (props.defaultLayout) {
            const layout = props.defaultLayout as EventLayout;
            setLayoutImage(layout.image);
            setLayoutImagePosition(layout.imagePosition);
            setBackgroundColor(layout.backgroundColor);
            setShowTitle(layout.showTitle);
            setTitleColor(layout.titleColor);
            setTitleSize(layout.titleFontSize);
            setShowSubtitle(layout.showSubtitle);
            setSubtitleColor(layout.subtitleColor);
            setSubtitleSize(layout.subtitleFontSize);
            setShowStartDate(layout.showDates);
            setStartdateColor(layout.datesColor);
            setStartdateSize(layout.datesFontSize);
        }
    }, [])

    useEffect(() => {
        modelChanged()
    }, [
        layoutImage,
        layoutImagePosition,
        backgroundColor,
        showTitle,
        titleColor,
        titleFontSize,
        showSubtitle,
        subtitleColor,
        subtitleFontSize,
        showStartDate,
        startdateColor,
        startdateFontSize
    ])

    function modelChanged() {
        props.layoutChanged({
            image: layoutImage,
            imagePosition: layoutImagePosition,
            backgroundColor: backgroundColor,
            showTitle: showTitle,
            titleColor: titleColor,
            titleFontSize: titleFontSize,
            showSubtitle: showSubtitle,
            subtitleColor: subtitleColor,
            subtitleFontSize: subtitleFontSize,
            showDates: showStartDate,
            datesColor: startdateColor,
            datesFontSize: startdateFontSize
        })
    }


    return (
        <div className='me-event-designer-container'>
            <div className='me-event-design-setting-container'>
                <div className='me-event-design-setting'>
                    <div className={'me-event-design-setting-image-position'}>
                        <Form.Label>Bild</Form.Label>
                        <Form.Select
                            value={layoutImagePosition}
                            onChange={(e) => setLayoutImagePosition(e.target.value as unknown as ImagePosition)}>
                            <option value="COVER">Ausgefüllt</option>
                            <option value="CONTAIN">Eingepasst</option>
                            <option value="TOP">Oben</option>
                        </Form.Select>
                    </div>
                    {layoutImagePosition !== ImagePosition.COVER && <input
                        className={`me-event-design-setting-color active`}
                        value={backgroundColor}
                        type='color' onChange={(e) => setBackgroundColor(e.target.value)}/>}
                </div>
                <div className='me-event-design-setting'>
                    <Form.Label>Titel</Form.Label>
                    <FontAwesomeIcon
                        className={`me-event-design-setting-activator ${showTitle ? ' active' : ''}`}
                        onClick={() => setShowTitle(!showTitle)} icon={'check'}/>
                    <input className={`me-event-design-setting-color ${showTitle ? ' active' : ''}`}
                           disabled={!showTitle}
                           value={titleColor}
                           type='color' onChange={(e) => setTitleColor(e.target.value)}/>
                    <input className={`me-event-design-property-font-size ${showTitle ? ' active' : ''}`}
                           disabled={!showTitle}
                           type='number' value={titleFontSize}
                           onChange={(e) => setTitleSize(e.target.value)}/>
                </div>
                <div className='me-event-design-setting'>
                    <Form.Label>Untertitel</Form.Label>
                    <FontAwesomeIcon
                        className={`me-event-design-setting-activator ${showSubtitle ? ' active' : ''}`}
                        onClick={() => setShowSubtitle(!showSubtitle)} icon={'check'}/>
                    <input className={`me-event-design-setting-color ${showSubtitle ? ' active' : ''}`}
                           disabled={!showSubtitle} value={subtitleColor} type='color'
                           onChange={(e) => setSubtitleColor(e.target.value)}/>
                    <input className={`me-event-design-property-font-size ${showSubtitle ? ' active' : ''}`}
                           disabled={!showSubtitle} type='number' value={subtitleFontSize}
                           onChange={(e) => setSubtitleSize(e.target.value)}/>
                </div>
                <div className='me-event-design-setting'>
                    <Form.Label>Datum</Form.Label>
                    <FontAwesomeIcon
                        className={`me-event-design-setting-activator ${showStartDate ? ' active' : ''}`}
                        onClick={() => setShowStartDate(!showStartDate)} icon={'check'}/>
                    <input className={`me-event-design-setting-color ${showStartDate ? ' active' : ''}`}
                           disabled={!showStartDate}
                           value={startdateColor}
                           type='color' onChange={(e) => setStartdateColor(e.target.value)}/>
                    <input className={`me-event-design-property-font-size ${showStartDate ? ' active' : ''}`}
                           disabled={!showStartDate}
                           type='number' value={startdateFontSize}
                           onChange={(e) => setStartdateSize(e.target.value)}/>
                </div>
            </div>
            <EventLayoutPreview
                imageChanged={setLayoutImage}
                imagePosition={layoutImagePosition}
                backgroundColor={backgroundColor}
                defaultImage={layoutImage}
                title={showTitle ? props.title : ''}
                titleColor={titleColor}
                titleFontSize={titleFontSize}
                subtitle={showSubtitle ? props.subtitle : ''}
                subtitleColor={subtitleColor}
                subtitleFontSize={subtitleFontSize}
                datesTimes={showStartDate ? props.dateTimes : []}
                startdateColor={startdateColor}
                startdateFontSize={startdateFontSize}
            />
        </div>
    )

}


export default EventLayoutDesigner;