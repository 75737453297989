export enum WeblinkTypes {
    BOOK = 'BOOK',
    TICKETS = 'TICKETS',
    TABLE_RESERVATION = 'TABLE_RESERVATION',
    PROGRAM = 'PROGRAM',
    MENU = 'MENU',
    WEEKLY_MENU = 'WEEKLY_MENU',
    LUNCH_MENU = 'LUNCH_MENU',
    WINE_CARD = 'WINE_CARD',
    RECOMMENDED = 'RECOMMENDED'
}

export function getWeblinkTypeLabel(type: WeblinkTypes): string {
    switch (type) {
        case WeblinkTypes.BOOK :
            return 'Buchen';
        case WeblinkTypes.TICKETS:
            return 'Tickets';
        case WeblinkTypes.TABLE_RESERVATION :
            return 'Tischreservation';
        case WeblinkTypes.PROGRAM :
            return 'Programm';
        case WeblinkTypes.MENU:
            return 'Menu';
        case WeblinkTypes.WEEKLY_MENU:
            return 'Wochenmenu';
        case WeblinkTypes.LUNCH_MENU:
            return 'Mittagsmenu';
        case WeblinkTypes.WINE_CARD:
            return 'Weinkarte';
        case WeblinkTypes.RECOMMENDED:
            return 'Empfohlen';

    }
    return 'Noch nicht defniiert';
}