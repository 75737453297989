import './VerticalMenu.scss';

import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface VerticalMenuItem {
    code: string;
    label: string;
    disabled: boolean;
}

interface VerticalMenuProps {
    items: VerticalMenuItem[];
    selectedCode?: string;
    onSelect: (code: string) => void;
    children: any;
}

const VerticalMenu = (props: VerticalMenuProps) => {

    const [selectedMenuCode, setSelectedMenuCode] = useState(props.items[0].code);
    const [barsActive, setBarsActive] = useState(false);

    useEffect(() => {
        if (props.selectedCode) {
            setSelectedMenuCode(props.selectedCode);
        }
    }, [props.selectedCode])

    function menuItemClicked(menuItem: VerticalMenuItem): void {
        if (menuItem.disabled) {
            return;
        }
        setSelectedMenuCode(menuItem.code);
        setBarsActive(false);
        props.onSelect(menuItem.code);
    }

    function toggleMenu() {
        setBarsActive(!barsActive);
    }


    return <div className='me-vertical-menu'>
        <FontAwesomeIcon
            className={`me-menu-vertical-bars ${barsActive ? 'barsActive' : ''}`}
            onClick={toggleMenu}
            icon={'bars'}></FontAwesomeIcon>
        <div className={`me-vertical-menu-menu ${barsActive ? 'barsActive' : ''}`}>
            {
                props.items.map(item => {
                    const selected = item.code === selectedMenuCode ? 'selected' : '';
                    const disabled = item.disabled ? 'disabled' : '';
                    return <div key={item.code} className={`me-vertical-menu-item ${selected} ${disabled}`}
                                onClick={() => menuItemClicked(item)}>
                        {item.label}
                    </div>
                })
            }
        </div>
        <div className='me-vertical-menu-content'> {props.children}</div>
    </div>


}

export default VerticalMenu;
