import React, {useState} from "react";
import ProfilMenuButton from "../profil-menu-button/ProfilMenuButton";
import GemeindeAddressEdit from "./gemeinde-address/GemeindeAddressEdit";
import GemeindeInfoEdit from "./gemeinde-info/GemeindeInfoEdit";

const GemeindeProfilMenu = () => {


    const [showAddressEdit, setShowAddressEdit] = useState(false);
    const [showInfoEdit, setShowInfoEdit] = useState(false);

    return (
        <>
            <div className='me-flex-column me-scrollable'>
                <ProfilMenuButton
                    label={'Adresse'} icon={'house-flag'}
                    onClick={() => setShowAddressEdit(true)}/>
                <ProfilMenuButton
                    label={'Informationen'} icon={'circle-info'}
                    onClick={() => setShowInfoEdit(true)}/>
            </div>
            {showAddressEdit && <GemeindeAddressEdit
                onSaved={() => setShowAddressEdit(false)}
                onCancel={() => setShowAddressEdit(false)}/>
            }
            {showInfoEdit && <GemeindeInfoEdit
                onSaved={() => setShowInfoEdit(false)}
                onCancel={() => setShowInfoEdit(false)}/>
            }
        </>
    )

}

export default GemeindeProfilMenu;