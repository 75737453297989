import './EventTile.scss'

import {Card} from "react-bootstrap";
import IconButton from "../../../ui/icon-button/IconButton";
import React, {useState} from "react";
import {EventService} from "../../../data/event/Event.service";
import {Event, EventState} from "../../../data/event/Event";
import StartEnddatesTimes from "../../../ui/date/StartEnddatesTimes";
import ConfirmYesCancel from "../../../ui/modal/ConfirmYesCancel";
import Spinner from "../../../ui/spinner/Spinner";
import {isRunningEvent} from "../../../data/event/EventUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OpenModalButton from "../../../ui/open-model-button/open-modal-button";
import EventCommentsOverview from "../comments/EventCommentsOverview";
import ClientsOverview from "../../client/overview/ClientsOverview";
import EventLayoutPreview from "../layout/preview/EventLayoutPreview";

export interface EventTileProps {
    event: Event;
    onEdit: (string: string) => void;
    onDeleted: () => void;
    onActivated: () => void;
}

const EventTile = (props: EventTileProps) => {

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [sending, setSending] = useState(false);
    const event = props.event;
    const isRunning = isRunningEvent(event);
    const clientIds = props.event.participants.map(p => p.clientId);

    function onDeleteEventClicked(e: any) {
        e.stopPropagation();
        setShowConfirmDelete(true);
    }

    function deleteEvent() {
        EventService.deleteEvent(props.event.eventId)
            .then(() => {
                props.onDeleted();
            })
    }

    function editEvent(e: any) {
        e.stopPropagation();
        props.onEdit(props.event.eventId);
    }

    function activateEvent(e: any, eventId: string) {
        e.stopPropagation();
        setSending(true);
        EventService.activate(eventId)
            .then(props.onActivated)
    }

    return (
        <>
            <ConfirmYesCancel
                showModal={showConfirmDelete}
                title={'Event wirklich löschen?'}
                message={event.title}
                onConfirm={deleteEvent}
                onCancel={() => setShowConfirmDelete(false)}/>
            <Card className={'me-event-card'}>
                <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                    {sending && <Spinner small={true}></Spinner>}
                    {!sending && <div className={'me-flex-row me-flex-grow'}>
                        <div className={'me-flex-column me-flex-grow'}>
                            <div className='me-flex-grow'>
                                <Card.Title>{event.title}</Card.Title>
                                <Card.Text className={'me-event-tile-subtitle'}>{event.subtitle}</Card.Text>
                                {isRunning &&
                                    <div className='me-event-running'>
                                        <FontAwesomeIcon icon={'person-running'}/>
                                    </div>}
                                <StartEnddatesTimes datesTimes={props.event.dates}/>
                            </div>
                            <div className={'me-event-state-container'}>
                                {event.state === EventState.CREATED &&
                                    <div className={'me-activate-event-button'}
                                         onClick={(e) => activateEvent(e, event.eventId)}>Veröffentlichen</div>}
                                {event.state === EventState.ACTIVATED &&
                                    <div className={'me-event-state active'}>{'Veröffentlicht'}</div>}
                            </div>
                        </div>
                        {event.layout.image &&
                            <Card.Img className={'me-event-card-image'} src={event.layout.image} alt=""/>}
                    </div>}


                    {!sending && <div className={'me-event-card-actions'}>
                        <div className={'me-event-card-acions-left'}>
                            <OpenModalButton
                                icon={'people-group'}
                                buttonLabel={event.participants.length as unknown as string}
                                modalTitle={'Teilnehmer'}>
                                <ClientsOverview clientIds={clientIds}/>
                            </OpenModalButton>
                            <OpenModalButton
                                buttonMarginLeft={10}
                                cssSelector='eventCommentsOverviewComponent'
                                icon={'comment'}
                                buttonLabel={event.comments.length as unknown as string}
                                modalTitle={'Kommentare'}>
                                <EventCommentsOverview event={props.event}/> </OpenModalButton>
                            <OpenModalButton
                                buttonMarginLeft={10}
                                buttonMarginRight={10}
                                icon={'play'}
                                buttonLabel={'Vorschau'}
                                modalTitle={'Event Vorschau'}>
                                <EventLayoutPreview
                                    defaultImage={event.layout.image}
                                    imagePosition={event.layout.imagePosition}
                                    backgroundColor={event.layout.backgroundColor}
                                    title={event.layout.showTitle ? event.title : ''}
                                    titleColor={event.layout.titleColor}
                                    titleFontSize={event.layout.titleFontSize}
                                    subtitle={event.layout.showSubtitle ? event.subtitle : ''}
                                    subtitleColor={event.layout.subtitleColor}
                                    subtitleFontSize={event.layout.subtitleFontSize}
                                    datesTimes={event.layout.showDates ? event.dates : []}
                                    startdateColor={event.layout.datesColor}
                                    startdateFontSize={event.layout.datesFontSize}
                                    imageChanged={() => console.log("test")}
                                    editDisabled={true}
                                />
                            </OpenModalButton>
                        </div>

                        <div className={'me-event-card-acions-right'}>
                            <IconButton iconName={'edit'} onClick={editEvent}/>
                            <IconButton iconName={'trash-can'} marginLeft={10} className={'red-hover'}
                                        onClick={onDeleteEventClicked}/>
                        </div>
                    </div>}

                </Card.Body>
            </Card>
        </>
    )
}

export default EventTile;