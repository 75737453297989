import {createSlice} from '@reduxjs/toolkit'

export interface SettingsState {
    show: boolean,
}

const initialState: SettingsState = {
    show: false,
}

const settingSlice = createSlice({
    name: 'setting',
    initialState: initialState,
    reducers: {
        showSettings(state) {
            state.show = true;
        },
        hideSettings(state) {
            state.show = false;
        }
    }
})

export const settingActions = settingSlice.actions;
export default settingSlice.reducer;