import './ConfirmYesCancel.scss';
import {Button, Modal} from "react-bootstrap";
import React from "react";


interface ConfirmYesCancelProps {
    showModal: boolean;
    title: string;
    textYes?: string;
    message?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmYesCancel = (props: ConfirmYesCancelProps) => {


    return <>
        <Modal show={props.showModal}
               backdrop="static"
               onHide={props.onCancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            {props.message && <Modal.Body className={'me-confirm-yes-cancel-body-message'}>{props.message}</Modal.Body>}
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancel}>Abbrechen</Button>
                <Button variant="primary" onClick={props.onConfirm}>{props.textYes ? props.textYes : 'OK'}</Button>
            </Modal.Footer>
        </Modal>
    </>

}

export default ConfirmYesCancel;