import './Header.scss'

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingActions} from "../../store/settings";
import ReactDom from "react-dom";
import Profil from "../profil/Profil";
import {RootState} from "../../store/store";
import Image from "react-bootstrap/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AcountState from "./AccountState";
import {Modal} from "react-bootstrap";
import {AuthService} from "../../data/auth/Auth.service";
import {RouteNames} from "../../route-names";
import {useNavigate} from "react-router-dom";
import IconButton from "../../ui/icon-button/IconButton";
import Company from "../../data/company/Company";
import {Gemeinde} from "../../data/gemeinde/Gemeinde";


const Header = () => {

    const overlayRoot: HTMLElement = document.getElementById('overlay-root') as HTMLElement;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const company = useSelector((state: RootState) => state.company.data);
    const gemeinde = useSelector((state: RootState) => state.gemeinde.data);
    const [showPayment, setShowPayment] = useState(false);

    const profileData = getProfileData(company, gemeinde);

    function onSettingsClicked(e: any) {
        e.preventDefault();
        dispatch(settingActions.showSettings());
    }

    function onLogout(event: any) {
        event.stopPropagation();
        AuthService.logout();
        dispatch(settingActions.hideSettings());
        navigate(RouteNames.LOGIN);
    }

    function getProfileData(company: Company | null, gemeinde: Gemeinde | null): { name: string, logo: string } {
        if (company) {
            return {name: company.company, logo: company.image}
        } else if (gemeinde) {
            return {name: gemeinde.name, logo: gemeinde.logo}
        }
        return {name: '', logo: ''};
    }

    return (
        <header>
            <AcountState/>

            <div className='me-flex-row me-flex-grow'>
                {profileData.logo &&
                    <Image src={profileData.logo} rounded={true} id={'me-header-company-settings'}
                           className='me-header-profile-image' alt='Profil logo'
                           onClick={(e) => onSettingsClicked(e)}>
                    </Image>}
                {!profileData.logo &&
                    <FontAwesomeIcon
                        className='me-header-profile-image no-image'
                        icon={'question'} onClick={(e) => onSettingsClicked(e)}/>
                }
                {profileData.name &&
                    <div className='me-header-profile-title'>{profileData.name}</div>
                }
            </div>

            <IconButton
                className={'me-logout-button white-outline'}  iconName={'arrow-right-from-bracket'}
                buttonLabel={'logout'} minWithIPad={true} onClick={onLogout}
            />
            <IconButton
                className={'white-outline'} iconName={'rocket'}
                buttonLabel={'Free plan'} minWithIPad={true} onClick={() => setShowPayment(true)}
            />
            <div>
                {
                    ReactDom.createPortal(
                        <Profil/>,
                        overlayRoot)
                }
            </div>
            <Modal show={showPayment}
                   className={'client-modal'}
                   backdrop={true}
                   onHide={() => setShowPayment(false)}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>Konto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'me-margin-xl'}>Sie haben ein kostenloses Konto</div>
                </Modal.Body>
            </Modal>
        </header>
    );

};

export default Header;