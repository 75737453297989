import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Post} from "../data/post/Post";

export interface PostsState {
    loading: boolean;
    posts: Post[];
}

const initialState: PostsState = {
    loading: false,
    posts: []
}

const postsSlice = createSlice({
    name: 'posts',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<Post[]>) {
            state.posts = action.payload;
        },
    }
})

export const postsActions = postsSlice.actions;
export default postsSlice.reducer;