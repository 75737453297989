import Company, {Adresse, CompanyProfilUpdate, newCompany} from "./Company";
import axiosInstance from "../../AxiosInterceptor";
import {SendFeedback} from "./Feedback";
import {CompanyProfileState} from "./CompanyProfileState";
import {OpeningHour, OpeningHoursState} from "./OpeningHour";

export class CompanyService {

    public static async read(companyId: string): Promise<Company> {
        const response = await axiosInstance.get('companies/' + companyId);
        return newCompany(response.data);
    }

    public static async getCredits(companyId: string): Promise<any> {
        const response = await axiosInstance.get('companies/getCredits/' + companyId, {});
        return response.data;
    }

    public static async update(companyProfilUpdate: CompanyProfilUpdate): Promise<void> {
        return await axiosInstance.post('companies/profile/', companyProfilUpdate);
    }

    public static async sendFeedbackToOnra(companyFeedback: SendFeedback): Promise<void> {
        return await axiosInstance.post('/companies/sendFeedbackToOnra', companyFeedback)
    }

    public static async updateOpeningHours(openingHours: OpeningHour[], openingHoursState: OpeningHoursState) {
        const response = await axiosInstance.post('/companies/profile/openinghours', {
            openingHours: openingHours,
            openingHoursState: openingHoursState
        })
        return newCompany(response.data);
    }

    public static async updateProfileState(companyProfileState: CompanyProfileState) {
        const response = await axiosInstance.post('/companies/profile/state', companyProfileState)
        return newCompany(response.data);
    }

    public static async updateAddress(adresse: Adresse): Promise<Company> {
        const response = await axiosInstance.post<Company>('/companies/profile/address', adresse)
        return response.data;
    }

}