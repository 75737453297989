import './CreateChatForm.scss';

import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../ui/spinner/Spinner";
import React, {useRef, useState} from "react";
import VisibilityToggler from "../../base/visibilty/visibility-toggler";
import {Visibility} from "../../../data/base/Visibility";
import {AddChat} from "../../../data/chat/Chat";
import InvitationList from "../../event/invitation-list/InvitationList";
import {ChatService} from "../../../data/chat/ChatService";

export interface CreateChatFormProps {
    showModal: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const CreateChatForm = (props: CreateChatFormProps) => {

    const formRef = useRef(null);

    const [error, setError] = useState('');
    const [isSaving, setSaving] = useState(false);
    const [isSavingActive, setSavingActive] = useState(true);
    const [validated, setValidated] = useState(false);
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');
    const [visibility, setVisibility] = useState(Visibility.PUBLIC);
    const [invitationClientIds, setInvitationClientIds] = useState<string[]>([])


    function onTopicChanged(event: any) {
        setError('');
        setTopic(event.target.value);
    }

    function onMessageChanged(event: any) {
        setError('');
        setMessage(event.target.value);
    }

    function onVisibilityChanged(visibility: Visibility) {
        setError('');
        if (visibility !== Visibility.LIST) {
            setInvitationClientIds([]);
        }
        setVisibility(visibility);
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function saved() {
        resetForm();
        props.onSave();
    }

    function resetForm() {
        setError(() => '');
        setValidated(false);
        setSaving(() => false);
        setTopic('');
        setVisibility(Visibility.PUBLIC);
    }

    function save() {
        const form = formRef.current as any;
        setError('');
        setValidated(true);
        if (!isValid()) {
            return;
        }
        if (form.checkValidity() === true) {
            setSaving(true);
            ChatService.add(createAddChat())
                .then(saved)
                .catch(() => setSaving(false));

        }
    }

    function isValid(): boolean {
        if (!topic) {
            setError("Thema erforderlich");
            return false;
        }
        return true;
    }

    function createAddChat(): AddChat {
        return {
            topic: topic,
            visibility: visibility,
            invitationList: invitationClientIds,
            message: message,
        }
    }

    function createForm() {
        return (
            <div>
                <Form ref={formRef} id='createChatForm'
                      noValidate
                      validated={validated}>
                    <div className='me-flex-grow'>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                required
                                placeholder={'Titel*'}
                                value={topic}
                                onChange={onTopicChanged}/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                as="textarea" rows={5}
                                required
                                placeholder={'Nachricht*'}
                                value={message}
                                onChange={onMessageChanged}/>
                        </Form.Group>
                        <Form.Group className="mt-3 mb-3">
                            <Form.Label>Bitte wähle das Zielpublikum für deine Channel aus.</Form.Label>
                            <VisibilityToggler
                                initialValue={visibility}
                                onChange={onVisibilityChanged}
                            />
                        </Form.Group>
                        {visibility === Visibility.LIST &&
                            <div className='me-create-thread-invitation-list-container'>
                                <InvitationList
                                    initialInvitationClientIds={invitationClientIds}
                                    invitationClientIdsChanged={setInvitationClientIds}
                                />
                            </div>
                        }
                    </div>

                </Form>
                <Alert className={`me-save-thread-alert ${error !== '' ? 'show-error' : ''}`}
                       variant='danger'>{error}</Alert>
            </div>
        )
    }

    return (
        <Modal show={props.showModal}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Channel erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner/>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving &&
                    <Button variant="primary" onClick={save} disabled={!isSavingActive}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    )

}

export default CreateChatForm;
