import './OpeningHoursEdit.scss'
import {Alert, Button, Col, Modal, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Spinner from "../../../../../ui/spinner/Spinner";
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {OpeningHour, WeekDays} from "../../../../../data/company/OpeningHour";

export interface OpeningHoursEditProps {
    showModal: boolean;
    dayOfTheWeek: WeekDays;
    openingHours: OpeningHour[];
    onSave: (openingHours: OpeningHour[]) => void;
    onCancel: () => void;
}


const OpeningHoursEditForm = (props: OpeningHoursEditProps) => {


    const [errorText, setErrorText] = useState('');

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [openingHours, setOpeningHours] = useState<OpeningHour[]>(props.openingHours);

    const [values, setValues] = useState<WeekDays[]>([props.dayOfTheWeek]);
    const [from, setFrom] = useState(getInitialFrom());
    const [to, setTo] = useState(getInitialTo());

    function getInitialFrom() {
        const foundHours = props.openingHours.find(hour => hour.weekday === props.dayOfTheWeek);
        if (foundHours) {
            return foundHours.from;
        }
        return undefined;
    }

    function getInitialTo() {
        const foundHours = props.openingHours.find(hour => hour.weekday === props.dayOfTheWeek);
        if (foundHours) {
            return foundHours.to;
        }
        return undefined;
    }

    function onFromChanged(event: any) {
        setFrom(event.target.value);
    }

    function onToChanged(event: any) {
        setTo(event.target.value);
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setError(false);
        setErrorText('');
    }

    function save() {
        setSaving(true);
        let otheropeningHours = openingHours
            .filter(openingHour => (!values.includes(openingHour.weekday)));
        values.forEach(value => {
            const newOpeningHour = {
                weekday: value,
                from: from,
                to: to
            };
            otheropeningHours.push(newOpeningHour);
        });
        setOpeningHours(otheropeningHours);
        props.onSave(otheropeningHours);
        setSaving(false);
    }

    const handleChange = (val: any) => setValues(val);


    function createForm() {
        return <>

            <ToggleButtonGroup className='d-flex opening-hours-week-days' type="checkbox"
                               value={values} defaultValue={values} onChange={handleChange}>
                <ToggleButton id="tbg-btn-1"
                              variant={'outline-primary'}
                              key={1}
                              value={WeekDays.MONDAY}>{'MO'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-2"
                              variant={'outline-primary'}
                              key={2}
                              value={WeekDays.TUESDAY}>{'DI'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-3"
                              variant={'outline-primary'}
                              value={WeekDays.WEDNESDAY}>{'MI'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-4"
                              variant={'outline-primary'}
                              value={WeekDays.THURSDAY}>{'DO'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-5"
                              variant={'outline-primary'}
                              value={WeekDays.FRIDAY}>{'FR'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-6"
                              variant={'outline-primary'}
                              value={WeekDays.SATURDAY}>{'SA'}
                </ToggleButton>
                <ToggleButton id="tbg-btn-7"
                              variant={'outline-primary'}
                              value={WeekDays.SUNDAY}>{'SO'}
                </ToggleButton>
            </ToggleButtonGroup>


            <Form>
                <Row className='mb-3'>
                    <Form.Group as={Col}>
                        <Form.Control
                            required type="time" defaultValue={from}
                            onChange={onFromChanged}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control
                            required type="time" defaultValue={to}
                            onChange={onToChanged}/>
                    </Form.Group>
                </Row>
            </Form>
            {error &&
                <Alert className='settings-error' variant='danger'>
                    {errorText}
                </Alert>
            }
        </>
    }

    return <>
        <Modal show={true}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Öffnungszeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'me-opening-hours-edit-body'}>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>
}

export default OpeningHoursEditForm;