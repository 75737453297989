import {Visibility} from "../base/Visibility";

export const SURVEY_CONST = {
    MAX_QUESTIONS: 6,
    MAX_ANSWERS: 8
}

export interface Survey {
    surveyId: string;
    companyId: string;
    state: SurveyState;
    visibility: Visibility;
    invitationList: string[];
    intro: SurveyIntro;
    questions: SurveyQuestion[];
    confirm: SurveyConfirm;
    enddate: string;
    created: string;
}

export interface SurveyQuestion {
    question: string;
    answers: SurveyAnswer[];
}

export interface SurveyAnswer {
    text: string;
}

export interface SurveyIntro {
    title: string;
    description: string;
}

export interface SurveyConfirm {
    title: string;
}

export interface AddSurvey {
    visibility: Visibility;
    invitationList: string[];
    enddate: Date;
    intro: SurveyIntro;
    confirm: SurveyConfirm;
    questions: SurveyQuestion[];
}

export enum SurveyState {
    CREATED = 'CREATED',
    ACTIVATED = 'ACTIVATED',
}

export interface SurveyResult {
    questionsResults: SurveyResultQuestion[];
}

export interface SurveyResultQuestion {
    question: string;
    answersResults: SurveyResultAnswer[];
}

export interface SurveyResultAnswer {
    text: string;
    count: number;
    percent: number;
}