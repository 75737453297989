import {AuthService} from "./data/auth/Auth.service";
import {useEffect, useState} from "react";
import {host} from "./data/host.env";
import axios from "axios";
import {RouteNames} from "./route-names";
import {useNavigate} from "react-router-dom";
import {Alert} from "react-bootstrap";

export const axiosInstance = axios.create({
    baseURL: host(),
});

const AxiosInterceptor = () => {
    const navigate = useNavigate();
    const [showAutoLoggedOut, setShowAutoLoggedOut] = useState(false);

    const responseHandling = (response: any) => {
        return response;
    }

    const errorHandling = (error: any) => {
        const originalRequest = error.config;
        if (error && error.response.status === 401 && !originalRequest.refreshCall) {
            originalRequest.refreshCall = true;
            return AuthService.refresh().then((token) => {
                originalRequest.headers.Authorization = "Bearer " + token;
                originalRequest.headers.ContentType = "application/json";
                return axiosInstance(originalRequest);
            }).catch(() => {
                setShowAutoLoggedOut(true);
                AuthService.logout();
                navigate(RouteNames.LOGIN)
            });
        }
        return Promise.reject(error);
    }

    const requestHandling = (config: any) => {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.Authorization = "Bearer " + AuthService.accessToken();
        return config;
    }

    useEffect(() => {
        axiosInstance.interceptors.request.use(requestHandling);
        axiosInstance.interceptors.response.use(responseHandling, errorHandling);
    }, [])

    return (
        <>
            {showAutoLoggedOut &&
                <div>
                    <Alert variant={"danger"} closeVariant={'white'}
                           onClose={() => setShowAutoLoggedOut(false)} dismissible style={{
                        position: 'absolute',
                        zIndex: 1001,
                        width: '80%',
                        height: '50%',
                        top: '10%',
                        left: '10%',
                        paddingLeft: '10vw',
                        paddingTop: '15vh',
                        fontSize: '20px',
                        color: 'white',
                        background: 'rgba(100, 100, 120)',
                    }}>
                        <h5>Sitzung ist abgelaufen</h5>
                        <br></br>
                        <h2>Bitte loggen Sie sich neu ein ...</h2>
                    </Alert>
                    <div style={{
                        position: 'absolute',
                        zIndex: 100,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.5)',
                    }}/>
                </div>
            }
        </>
    )
}

export default axiosInstance;
export {AxiosInterceptor}
