import {OpeningHour, OpeningHoursState} from "./OpeningHour";
import {DateTime} from "../base/DateTime";
import {CompanyProfileState} from "./CompanyProfileState";
import {CompanyWeblinks} from "./weblinks/CompanyWeblinks";

export enum CompanyState {
    VERIFIED,
    NOT_VERIFIED
}

export interface Adresse {
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
}

export interface CompanyProfilUpdate {
    id: string;
    company: string;
    image: string;
    email: string;
    telefon: string;
    specialOpeningHours: DateTime[];
    adresse: Adresse;
}

export interface Company {
    id: string;
    testaccount: boolean;
    company: string;
    image: string;
    email: string;
    ort: string;
    plz: string;
    telefon: string;
    weblinks: CompanyWeblinks | null;
    openingHours: OpeningHour[];
    openingHoursState: OpeningHoursState;
    specialOpeningHours: DateTime[];
    state: CompanyState;
    adresse: Adresse;
    profileState: CompanyProfileState;
    source: string;
    credits: number;
}

export function newCompany(data: any): Company {
    return {
        id: data.id,
        testaccount: data.testaccount,
        company: data.company,
        email: data.email,
        image: data.image,
        plz: data.plz,
        ort: data.ort,
        telefon: data.telefon,
        openingHours: !data.openingHours ? [] : data.openingHours,
        openingHoursState: {
            message: data.openingHoursState ? data.openingHoursState.message : ''
        },
        specialOpeningHours: !data.specialOpeningHours ? [] : data.specialOpeningHours,
        adresse: !data.adresse ? {street: "", streetNumber: "", zip: "", city: ""} : {
            street: data.adresse.street,
            streetNumber: data.adresse.streetNumber,
            zip: data.adresse.zip,
            city: data.adresse.city
        },
        weblinks: data.weblinks,
        profileState: data.profileState,
        state: data.state,
        source: data.source,
        credits: !data.credits ? 0 : data.credits
    };
}

export default Company;