import {StringUtil} from "./StringUtil";

export class TelefonUtil {

    static formatString(inputNumber: string) {
        if (!inputNumber) {
            return '';
        }
        const numericInput = StringUtil.removeNonNumerics(inputNumber);
        // Format the number with spaces as the user types
        let formattedNumber = `+${numericInput.slice(0, 2)}`;
        if (numericInput.length > 2) {
            formattedNumber += ` ${numericInput.slice(2, 4)}`;
        }
        if (numericInput.length > 4) {
            formattedNumber += ` ${numericInput.slice(4, 7)}`;
        }
        if (numericInput.length > 7) {
            formattedNumber += ` ${numericInput.slice(7, 9)}`;
        }
        if (numericInput.length > 9) {
            formattedNumber += ` ${numericInput.slice(9, 11)}`;
        }
        return formattedNumber;
    }

}