import './DateTimeEditorRow.scss'

import React, {useState} from "react";
import {DateTime} from "../../../data/base/DateTime";
import Form from "react-bootstrap/Form";
import IconButton from "../../../ui/icon-button/IconButton";
import {DateUtil} from "../../../data/DateUtil";

export interface DateTimeEditorRowProps {
    dateTime: DateTime;
    deleteDisabled?: boolean;
    onDelete: () => void;
    onChange: (datesTimes: DateTime) => void;
}

const DateTimeEditorRow = (props: DateTimeEditorRowProps) => {

    const [dateTime, setDateTime] = useState(props.dateTime);

    function onDateChanged(event: any) {
        const newDateTime = {...dateTime, date: event.target.value};
        setDateTime(newDateTime);
        props.onChange(newDateTime);
    }

    function onStarttimeChanged(event: any) {
        const newDateTime = {...dateTime, from: event.target.value};
        setDateTime(newDateTime);
        props.onChange(newDateTime);
    }

    function onEndtimeChanged(event: any) {
        const newDateTime = {...dateTime, to: event.target.value};
        setDateTime(newDateTime);
        props.onChange(newDateTime);
    }

    function removeRow() {
        if (!props.deleteDisabled) {
            props.onDelete();
        }
    }

    return (
        <>
            <Form className={'me-date-times-row'}>
                <Form.Control
                    className='me-datetime-row-date' required type="date"
                    min={DateUtil.simpleTodayString()}
                    value={dateTime.date}
                    onChange={onDateChanged}/>
                <Form.Control
                    className={'me-datetime-row-starttime'} required type="time"
                    title={'Von'}
                    value={dateTime.from}
                    onChange={onStarttimeChanged}/>
                <Form.Control
                    className={'me-datetime-row-endtime'} required type="time"
                    title={'Bis'}
                    value={dateTime.to}
                    onChange={onEndtimeChanged}/>
                <IconButton
                    className={`me-datetimes-delete-button red-hover ${props.deleteDisabled ? 'disabled' : ''}`}
                    iconName={'trash-can'} marginLeft={10}
                    onClick={removeRow}/>
            </Form>
        </>
    )

}

export default DateTimeEditorRow;