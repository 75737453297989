import './App.scss';
import React, {useEffect, useState} from "react";
import Header from "./components/header/Header";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import CustomerPage from "./pages/customer/CustomerPage";
import Confirm from "./ui/confirm/Confirm";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import Login from "./pages/login/Login";
import {RouteNames} from "./route-names";
import {AuthService} from "./data/auth/Auth.service";
import Signup from "./pages/signup/Signup";
import {useDispatch} from "react-redux";
import Dashboard from "./components/dashboard/Dashboard";
import EventsPage from "./pages/events/EventsPage";
import AppDataLoader from "./AppDataLoader";
import {AxiosInterceptor} from "./AxiosInterceptor";
import PostPage from "./pages/post/PostPage";
import WelcomeMessage from "./components/welcome/Welcome";
import Tour from "./components/tour/Tour";
import Help from "./components/help/Help";
import SurveyPage from "./pages/survey/SurveyPage";
import PasswordReset from "./pages/password-reset/PasswordReset";
import ChatPage from "./pages/chat/ChatPage";
import {accountActions} from "./store/account";
import {Account} from "./data/account/Account";

const App = () => {

    library.add(fas)

    const isLoggedIn = AuthService.isLoggedIn();
    const isDevelopment = AuthService.isDevelopment();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showWelcomeMessage, setShowWelcomeMessage] = useState(sessionStorage.getItem('newCompanySignUp'));
    const [showWelcomeTour, setShowWelcomeTour] = useState(false);


    useEffect(() => {
        if (!isLoggedIn) {
            navigate(RouteNames.LOGIN);
        }
    }, [isLoggedIn])


    useEffect(() => {
        restoreUser();
    }, [])

    useEffect(() => {
        setShowWelcomeMessage(sessionStorage.getItem('newCompanySignUp'));
    }, [sessionStorage.getItem('newCompanySignUp')])

    function restoreUser() {
        const accountString = AuthService.readAccount();
        if (accountString) {
            const accountData = JSON.parse(accountString);
            const account: Account = {
                email: accountData.email,
                companyId: accountData.companyId,
                gemeindeId: accountData.gemeindeId
            }
            dispatch(accountActions.setAccount(account));
        }
    }

    function clearWelcomeMessage() {
        setShowWelcomeMessage(null);
        sessionStorage.removeItem('newCompanySignUp');
        setShowWelcomeTour(true);
        sessionStorage.setItem('isWelcomeTour', 'true');
    }

    function clearWelcomeTour() {
        setShowWelcomeTour(false)
        sessionStorage.removeItem('isWelcomeTour');
    }

    return (
        <div className='AppContainer'>
            {isDevelopment && <div className={'me-is-development'}>Development</div>}
            {isLoggedIn && <Header/>}
            {isLoggedIn && <AppDataLoader></AppDataLoader>}
            <AxiosInterceptor/>
            <main>
                {isLoggedIn && <Dashboard></Dashboard>}
                <Routes>
                    <Route path='/' element={<Navigate replace to="/clients"/>}/>
                    <Route path={RouteNames.CLIENTS} element={<CustomerPage/>}/>
                    <Route path={RouteNames.CHATS} element={<ChatPage/>}/>
                    <Route path={RouteNames.EVENTS} element={<EventsPage/>}/>
                    <Route path={RouteNames.POSTS} element={<PostPage/>}/>
                    <Route path={RouteNames.SURVEY} element={<SurveyPage/>}/>
                    <Route path={RouteNames.LOGIN} element={<Login/>}/>
                    <Route path={RouteNames.SIGNUP} element={<Signup/>}/>
                    <Route path={RouteNames.PASSWORD_RESET} element={<PasswordReset/>}/>
                </Routes>
            </main>
            <Confirm/>
            {isLoggedIn && <Help/>}
            {showWelcomeMessage && <WelcomeMessage showModal={true} onCancel={clearWelcomeMessage}/>}
            {showWelcomeTour && <Tour onCancel={clearWelcomeTour}></Tour>}
        </div>
    )

}

export default App;
