import './EventsPage.scss';

import Spinner from "../../ui/spinner/Spinner";
import IconButton from "../../ui/icon-button/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {eventsActions} from "../../store/events";
import {EventService} from "../../data/event/Event.service";
import EventTile from "../../components/event/tile/EventTile";
import {useState} from "react";
import CreateEventForm from "../../components/event/create/CreateEventForm";
import {Event} from "../../data/event/Event";
import {ToggleButton} from "react-bootstrap";
import {isEventActive, sortEvent} from "../../data/event/EventUtil";


const EventsPage = () => {

    const dispatch = useDispatch();

    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;
    const events = useSelector((state: RootState) => state.events.events);
    const loading = useSelector((state: RootState) => state.events.loading);

    const [showMutation, setShowMutation] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [eventContext, setEventContext] = useState<Event>();

    function refreshEvents() {
        dispatch(eventsActions.loading(true));
        EventService.searchEvents(companyId).then(events => {
            dispatch(eventsActions.loading(false));
            dispatch(eventsActions.replaceAll(events))
        });
    }

    function onEditEventCLicked(eventId: string) {
        const event = events.find(e => e.eventId === eventId) as unknown as Event;
        setEventContext(event);
        setShowMutation(() => true);
    }

    function onNewEventClicked() {
        setEventContext(undefined);
        setShowMutation(() => true);
    }

    return (
        <>
            {loading && <Spinner></Spinner>}
            {!loading && <div className='me-page-container'>
                <div className='me-notification-page-menu'>
                    <div className={'me-flex-grow'}>
                        <IconButton iconName={'add'} onClick={onNewEventClicked}/>
                    </div>
                    <div>
                        <div>
                            <ToggleButton
                                className={'me-badge-button' + (showInactive ? ' active' : "")} id="toggle-check"
                                type="checkbox"
                                variant="light" checked={showInactive} value={"1"}
                                onChange={() => setShowInactive(() => !showInactive)}>
                                Abgelaufen
                            </ToggleButton>
                        </div>
                    </div>
                </div>
                <div className={'me-event-page-list-container'}>
                    {events
                        .filter(event => showInactive ? true : isEventActive(event))
                        .sort(sortEvent())
                        .map(event => (
                            <EventTile
                                key={event.eventId}
                                event={event}
                                onEdit={onEditEventCLicked}
                                onDeleted={refreshEvents}
                                onActivated={refreshEvents}
                            />
                        ))}
                </div>
            </div>}
            <CreateEventForm
                showModal={showMutation}
                initialEvent={eventContext}
                onSave={() => {
                    setShowMutation(false);
                    refreshEvents();
                }}
                onCancel={() => setShowMutation(false)}/>
        </>
    )
}

export default EventsPage;