import axiosInstance from "../../AxiosInterceptor";
import {GemeindeInfo, GemeindeLocation, mapGemeinde} from "./Gemeinde";
import {Address} from "../address/Address";

export class GemeindeService {


    public static async updateLogo(logo: string): Promise<void> {
        return await axiosInstance.post('gemeinden/logo/', {logo: logo});
    }

    public static async read(gemeindeId: string) {
        const response = await axiosInstance.get('gemeinden/' + gemeindeId);
        return mapGemeinde(response.data);
    }

    public static async updateAddress(gemeindeId: string, updateAddress: {
        address: Address;
        locations: GemeindeLocation[]
    }) {
        return await axiosInstance.post('gemeinden/' + gemeindeId + '/address/', {updateAddress});
    }

    public static async updateInfo(gemeindeId: string, gemeindeInfo: GemeindeInfo) {
        return await axiosInstance.post('gemeinden/' + gemeindeId + '/info/', gemeindeInfo);
    }
}