import {Coordinate} from "../address/Coordinate";
import {Address} from "../address/Address";

export interface Gemeinde {
    id: string;
    name: string;
    logo: string;
    email: string;
    address: Address;
    locations: GemeindeLocation[];
    info: GemeindeInfo;
}

export interface GemeindeLocation {
    id: string;
    name: string;
    coordinate: Coordinate
}

export interface GemeindeInfo {
    population: number;
    landAreaSquareKilometer: number;
    peoplePerSquareKilometer: number;
    foreignPopulationPercentage: number;
}

export function mapGemeinde(data: any): Gemeinde {
    return {
        id: data.id,
        name: data.name,
        logo: data.logo,
        email: data.email,
        address: data.address,
        locations: data.locations,
        info: data.info
    }

}