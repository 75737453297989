import './ClientTile.scss';

import {Client} from "../../../data/client/Client";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClientUtil} from "../../../data/client/ClientUtil";
import Image from "react-bootstrap/Image";

export interface ClientTileProps {
    client: Client;
    isKontakt: boolean;
    onClick: () => void;
}

const ClientTile = (props: ClientTileProps) => {

    const client = props.client;
    const kontaktIcon = props.isKontakt ? 'user' : 'globe';
    const kontaktLabel = props.isKontakt ? 'Kontakt' : 'Follower';

    return (
        <div onClick={props.onClick} className={'me-client-tile'}>
            {!client.profileImage && <div className={'me-client-tile-avatar'}>
                <FontAwesomeIcon icon={'user'}/>
            </div>}
            {client.profileImage && <div className={'me-client-tile-profile-image'}>
                {client.profileImage && <Image src={client.profileImage}/>}
            </div>}
            <div className={'me-flex-column me-flex-grow'}>
                <div className={'me-flex-column me-flex-grow'}>
                    <div className={'me-client-tile-name'}>{client.firstname} {client.name}</div>
                    <div className={'me-small'}>{ClientUtil.concatHome(client.contact)}</div>
                </div>
                <div className={'me-client-tile-contact'}>
                    <FontAwesomeIcon icon={kontaktIcon}/>
                    <div>{kontaktLabel}</div>
                </div>
            </div>
        </div>
    )

}

export default ClientTile;