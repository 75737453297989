import './ChatCommentsOverview.scss';
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {DateUtil} from "../../../data/DateUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReplyChatComment from "./reply/ReplyChatComment";
import AddChatComment from "./add/AddChatComment";
import {ChatService} from "../../../data/chat/ChatService";
import {Chat} from "../../../data/chat/Chat";
import {chatsActions} from "../../../store/chats";

export interface ChatCommentsOverviewProps {
    chat: Chat;
}

const ChatCommentsOverview = (props: ChatCommentsOverviewProps) => {
    const chatListRef = useRef(null) as any;

    const dispatch = useDispatch();
    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;
    const chat = props.chat;
    const allComments = props.chat.comments;
    const mainComments = allComments.filter(c => !c.parentCommentId);

    function Replays(props: any) {
        const replayComments = allComments.filter(c => c.parentCommentId === props.parentId);
        return (
            <div>
                {replayComments.map(replayComment => (
                    <div key={replayComment.commentId}>
                        <div
                            className={`me-chat-comment-overview-message-container replay`}>
                            <FontAwesomeIcon className={'me-chat-comment-reply-icon'}
                                             icon={'reply'}></FontAwesomeIcon>
                            <div className={'me-comment-message'}>{replayComment.message}</div>
                            <div className={'me-comment-info'}>
                                <div className={'me-comment-user-name'}>{replayComment.userName}</div>
                                <div
                                    className={'me-comment-created'}>{DateUtil.displayShort(replayComment.created)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    useEffect(() => {
        chatListRef.current?.scrollIntoView({behavior: 'auto'});
    }, []);

    function refresh() {
        ChatService.read(chat.chatId).then(chat => {
            dispatch(chatsActions.replaceChat(chat));
        });
    }

    return (
        <div className={'me-chat-comment-overview-container'}>
            <div className={'me-chat-comment-overview-list'}>
                {mainComments.map(comment => (
                    <div ref={chatListRef} className={'me-chat-comment-overview-list-item'} key={comment.commentId}>
                        <div
                            className={`me-chat-comment-overview-message-container ${comment.userId === companyId ? 'company-message' : ''}`}>
                            <div className={'me-comment-message'}>{comment.message}</div>
                            <div className={'me-comment-info'}>
                                <div className={'me-comment-user-name'}>{comment.userName}</div>
                                <div className={'me-comment-created'}>{DateUtil.displayShort(comment.created)}</div>
                            </div>
                        </div>
                        <Replays parentId={comment.commentId}/>
                        {comment.userId !== companyId &&
                            <ReplyChatComment
                                parentCommentId={comment.commentId}
                                chatId={chat.chatId}
                                reply={refresh}
                            />
                        }
                    </div>
                ))}
            </div>
            <div className={'me-add-chat-comment-container'}>
                <AddChatComment
                    chatId={chat.chatId}
                    add={refresh}/>
            </div>
        </div>
    )
}

export default ChatCommentsOverview;