import './Dropdown.scss';
import {Dropdown as BootstrapDropdown, DropdownButton} from "react-bootstrap";
import React, {useEffect, useState} from "react";

export interface DropdownValue {
    code: string,
    label: string
}

interface DropdownProps {
    value: string;
    showRequired: boolean;
    values: DropdownValue[];
    onSelected: (code: string) => void;
}

const Dropdown = (props: DropdownProps) => {

    const [selectedValue, setSelectedValue] = useState<string>(props.value);

    useEffect(() => {
        setSelectedValue(props.value);
    }, [props.value])

    let showRequired = props.showRequired;
    let title = props.values.find(v => v.code === selectedValue)?.label;
    if (!title) {
        showRequired = props.showRequired;
        title = 'Bitte wählen ...';
    }

    function onSelect(value: DropdownValue) {
        setSelectedValue(() => value.code as string);
        props.onSelected(value.code);
    }

    return (
        <DropdownButton title={title} className={`me-dropdown-button ${showRequired ? 'missing' : ''}`}>
            {props.values.map(value => {
                return <BootstrapDropdown.Item
                    key={value.code}
                    onClick={() => onSelect(value)}>{value.label}
                </BootstrapDropdown.Item>
            })}
        </DropdownButton>
    )


}

export default Dropdown;