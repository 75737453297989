import './StartEnddatesTimes.scss';
import React from "react";
import {DateTime} from "../../data/base/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateUtil} from "../../data/DateUtil";

interface StartEnddatesTimesProps {
    datesTimes: DateTime[];
}


const StartEnddatesTimes = (props: StartEnddatesTimesProps) => {


    return (
        <div className='me-startenddates-times'>
            {
                props.datesTimes.map(dateTime => {
                    return (
                        <div key={dateTime.date} className={'me-startenddate-row'}>
                            <div className={'me-startenddaterow-date'}>{DateUtil.displayShortNoTime(dateTime.date)}</div>
                            <FontAwesomeIcon className={'me-startenddaterow-time-icon'} icon={'clock'}/>
                            <div>{dateTime.from}</div>
                            <div className='me-date-separator'>&ndash;</div>
                            <div>{dateTime.to}</div>
                        </div>
                    )
                })
            }
        </div>
    )

};


export default StartEnddatesTimes;