import './CustomerPage.scss';
import {useEffect, useState} from "react";
import {ClientService} from "../../data/client/Client.service";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Client} from "../../data/client/Client";
import {Alert} from "react-bootstrap";
import Spinner from "../../ui/spinner/Spinner";
import ClientModal from "../../components/client/modal/ClientModal";
import ClientTile from "../../components/client/tile/ClientTile";
import {ClientUtil} from "../../data/client/ClientUtil";

const CustomerPage = () => {

    const [loadingClients, setLoadingClients] = useState(false);

    const [showClient, setShowClient] = useState<Client | null>(null)
    const [error, setError] = useState('');
    const [hasInvalidClients, setHasInvalidClients] = useState(false);
    const [customers, setCustomers] = useState<Client[]>([]);
    const subscriptionsLoading = useSelector((state: RootState) => state.subscriptions.loading);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    useEffect(() => {
        if (!subscriptionsLoading) {
            setError('');
            setLoadingClients(true);
            const clientIds = subscriptions.map(s => s.clientId);
            ClientService.readBulk(clientIds).then(bulk => {
                setCustomers(bulk.clients);
                setHasInvalidClients(bulk.clientsNotFound.length > 0);
                setLoadingClients(false);
            }).catch(e => {
                setLoadingClients(false);
                setError(e.message)
            });
        }
    }, [subscriptionsLoading])

    function isContact(clientId: string): boolean {
        return subscriptions.some(s => s.clientId === clientId && s.isContact);
    }

    return (
        <div className='me-page-container'>
            <div className='me-flex-grow'>
                {error && <Alert variant={"danger"}>{error}</Alert>}
                {loadingClients && <Spinner></Spinner>}
                {!loadingClients && !error &&
                    <>
                        <div className={'me-client-page-list-container'}>
                            {customers.length === 0 &&
                                <div> Noch kein Kunde vorhanden</div>
                            }
                            {customers.sort(ClientUtil.sort).map(client => (
                                <ClientTile
                                    key={client.id}
                                    client={client}
                                    isKontakt={isContact(client.id)}
                                    onClick={() => setShowClient(client)}/>
                            ))}
                        </div>
                    </>}
            </div>
            {hasInvalidClients &&
                <Alert variant='warning'>
                    Nicht alle Kunden konnten geladen werden. Bitte benachrichtigen sie den Support
                </Alert>
            }
            <ClientModal
                isContact={!!showClient && isContact(showClient.id)}
                client={showClient}
                onCancel={() => setShowClient(null)}
            />
        </div>
    )

}

export default CustomerPage;