import {GemeindeLocation} from "../../../../../data/gemeinde/Gemeinde";
import Form from "react-bootstrap/Form";
import {Button, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

export interface GemeindeLocationFormRowProps {
    location: GemeindeLocation;
    onDelete: () => void;
    onChanged: (location: GemeindeLocation) => void;
}

const GemeindeLocationsFormRow = (props: GemeindeLocationFormRowProps) => {

    const [name, setName] = useState(props.location.name);
    const [latitude, setLatitude] = useState(props.location.coordinate.latitude);
    const [longitude, setLongitude] = useState(props.location.coordinate.longitude);

    useEffect(() => {
        props.onChanged(mapFromState());
    }, [name, latitude, longitude]);

    function mapFromState(): GemeindeLocation {
        return {
            id: props.location.id,
            name: name,
            coordinate: {
                latitude: latitude ? latitude : '',
                longitude: longitude ? longitude : ''
            }
        }
    }

    return (
        <>
            <Form.Control
                id='name'
                placeholder={'Name*'}
                onChange={(event) => setName(event.target.value)}
                value={name}
                required>
            </Form.Control>
            <Row>
                <Form.Group as={Col} className="mt-3 mb-3">
                    <Form.Control
                        id='latitude'
                        placeholder={'Latitude*'}
                        onChange={(event) => setLatitude(event.target.value)}
                        value={latitude}
                        required>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="mt-3 mb-3">
                    <Form.Control
                        id='longitude'
                        placeholder={'Longitude*'}
                        onChange={(event) => setLongitude(event.target.value)}
                        value={longitude}
                        required>
                    </Form.Control>
                </Form.Group>
            </Row>
            <Button variant="secondary" onClick={props.onDelete}> Löschen </Button>
        </>
    )

}

export default GemeindeLocationsFormRow;