import './DatesTimesEditor.scss'

import React, {useEffect, useState} from "react";
import {DateTime} from "../../../data/base/DateTime";
import DateTimeEditorRow from "./DateTimeEditorRow";
import IconButton from "../../../ui/icon-button/IconButton";

export interface DateEditorProps {
    values?: DateTime[];
    onChange: (datesTimes: DateTime[]) => void;
    minOne?: boolean;
}

const DatesTimesEditor = (props: DateEditorProps) => {

    const [datesTimes, setDateTimes] = useState<DateTime[]>(props.values || []);

    useEffect(() => {
        checkLeastOneRowRequired();
    })

    // eslint-disable-next-line
    useEffect(() => {
        if (props.values) {
            setDateTimes(props.values);
            checkLeastOneRowRequired();
        }
        // eslint-disable-next-line
    }, [props.values])

    function checkLeastOneRowRequired() {
        if (props.minOne && datesTimes.length === 0) {
            const newDateTImes = [...datesTimes, {date: '', from: '', to: ''}]
            setDateTimes(newDateTImes);
        }
    }

    function onDatesTimesChanged(dateTime: DateTime, index: number) {
        const newDatesTimes = [...datesTimes];
        newDatesTimes[index] = dateTime;
        setDateTimes(newDatesTimes);
        props.onChange(newDatesTimes);
    }

    function addRow() {
        const newDateTime: DateTime = {
            date: '',
            from: '',
            to: '',
        }
        const newDatesTimes = [...datesTimes, newDateTime];
        setDateTimes(newDatesTimes);
        props.onChange(newDatesTimes);
    }

    function removeDate(index: number) {
        const newDatesTimes = [...datesTimes];
        newDatesTimes.splice(index, 1);
        setDateTimes(newDatesTimes);
        props.onChange(newDatesTimes);
    }

    return (
        <>
            {datesTimes.map((dateTime, index) => {
                return <DateTimeEditorRow
                    onChange={(dateTime) => onDatesTimesChanged(dateTime, index)}
                    deleteDisabled={props.minOne ? datesTimes.length <= 1 : false}
                    key={dateTime.date + index}
                    dateTime={dateTime}
                    onDelete={() => removeDate(index)}
                />
            })}
            <div className={'me-add-datetime'}>
                <IconButton iconName={'add'} onClick={addRow}/>
            </div>
        </>
    )

}

export default DatesTimesEditor;