import './open-modal-button.scss';

import React, {forwardRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface OpenModalButtonProps {
    icon: any;
    children: any;
    buttonLabel?: string;
    modalTitle: string;
    buttonMarginLeft?: number;
    buttonMarginRight?: number;
    modalMargin?: number;
    modalWidth?: number;
    cssSelector?: string
    modalSize?: 'sm' | 'lg' | 'xl';
}

const OpenModalButton = forwardRef((props: OpenModalButtonProps, ref: any) => {

    const [showModal, setShowModal] = useState(false);
    const buttonPropsStyle: any = {};
    if (props.buttonMarginLeft) {
        buttonPropsStyle.marginLeft = props.buttonMarginLeft + 'px';
    }
    if (props.buttonMarginRight) {
        buttonPropsStyle.marginRight = props.buttonMarginRight + 'px';
    }

    function close() {
        setShowModal(false);
    }

    return (
        <>
            <div style={buttonPropsStyle} className={'me-open-modal-button-container'}>
                <Button
                    variant={"outline-secondary"}
                    className='me-open-modal-button'
                    onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                    {props.buttonLabel !== undefined && <div className={'me-open-modal-button-label'}>{props.buttonLabel}</div>}
                </Button>
            </div>
            <Modal show={showModal}
                   className={props.cssSelector}
                   backdrop="static"
                   size={props.modalSize ? props.modalSize : 'lg'}
                   onHide={() => setShowModal(false)}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={'me-open-modal-button-modal-header'}>
                            <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                            <div>{props.modalTitle}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>Schliessen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
});

export default OpenModalButton;