import './EditWeblinks.scss';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import React, {useEffect, useState} from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import InfoListBox from "../../../../ui/info-box/info-list-box";
import {CompanyVrTour, CompanyWeblinks, CompanyWeblinksItem} from "../../../../data/company/weblinks/CompanyWeblinks";
import {CompanyWeblinksService} from "../../../../data/company/weblinks/CompanyWeblinks.service";
import ExternalLink from "../../../../ui/link/ExternalLink";
import {CompanyService} from "../../../../data/company/Company.service";
import {settingActions} from "../../../../store/settings";
import ImageSelector from "../../../../ui/image-selector/ImageSelector";
import Label from "../../../../ui/form/Label";
import Dropdown from "../../../../ui/dropdown/Dropdown";
import {WeblinkUtil} from "../../../../data/WeblinkUtil";
import {FormUtil} from "../../../../data/FormUtil";
import {getWeblinkTypeLabel, WeblinkTypes} from "../../../../data/company/weblinks/WeblinkTypes";
import {companyActions} from "../../../../store/company";

export interface EditWeblinksProps {
    onSave: () => void;
    onCancel: () => void;
}

const EditCompanyAbout = (props: EditWeblinksProps) => {
    const dispatch = useDispatch();

    const companyId = useSelector((state: RootState) => state.company?.data?.id);
    const company = useSelector((state: RootState) => state.company?.data);

    const [website, setWebsite] = useState<string>('');
    const [bookingLink, setBookingLink] = useState<string>('');
    const [vrTourUrl, setVrTourUrl] = useState<string>('');
    const [vrTourImage, setVrTourImage] = useState<string>('');

    const [link1Code, setLink1Code] = useState<string>('');
    const [link1Url, setLink1Url] = useState<string>('');
    const [link2Code, setLink2Code] = useState<string>('');
    const [link2Url, setLink2Url] = useState<string>('');
    const [link3Code, setLink3Code] = useState<string>('');
    const [link3Url, setLink3Url] = useState<string>('');

    const [isSaving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState('');
    const weblinks = company?.weblinks;

    const EXTRA_LINK_TYPES = createExtraLinks();

    function createExtraLinks(): any[] {
        let extraTypes: any[] = [];
        let type: keyof typeof WeblinkTypes;
        for (type in WeblinkTypes) {
            const weblinkType = WeblinkTypes[type];
            extraTypes.push({code: type, label: getWeblinkTypeLabel(weblinkType)});
        }
        return extraTypes;
    }

    useEffect(() => {
        if (weblinks) {
            setWebsite(weblinks.website);
            setBookingLink(weblinks.bookingLink);
            if (weblinks.weblinksItems[0]) {
                setLink1Code(weblinks.weblinksItems[0].code)
                setLink1Url(weblinks.weblinksItems[0].url)
            }
            if (weblinks.weblinksItems[1]) {
                setLink2Code(weblinks.weblinksItems[1].code)
                setLink2Url(weblinks.weblinksItems[1].url)
            }
            if (weblinks.weblinksItems[2]) {
                setLink3Code(weblinks.weblinksItems[2].code)
                setLink3Url(weblinks.weblinksItems[2].url)
            }
            if (weblinks.vrTour) {
                setVrTourUrl(weblinks.vrTour.url);
                setVrTourImage(weblinks.vrTour.image);
            }
        }
    }, [weblinks]);


    function cancel() {
        props.onCancel();
    }

    function mapVrTour(): CompanyVrTour | null {
        if (vrTourImage && vrTourUrl) {
            return {
                image: vrTourImage,
                url: WeblinkUtil.concatWithProtocol(vrTourUrl),
            }
        }
        return null;
    }

    function mapWebLinksItem(): CompanyWeblinksItem[] {
        const items: CompanyWeblinksItem[] = [];
        if (link1Url) {
            items.push({
                code: link1Code,
                url: WeblinkUtil.concatWithProtocol(link1Url),
            })
        }
        if (link2Url) {
            items.push({
                code: link2Code,
                url: WeblinkUtil.concatWithProtocol(link2Url),
            })
        }
        if (link3Url) {
            items.push({
                code: link3Code,
                url: WeblinkUtil.concatWithProtocol(link3Url),
            })
        }
        return items;
    }

    async function save() {
        if (!validate()) {
            return;
        }

        try {
            setErrorText('');
            setSaving(true);
            const weblinks: CompanyWeblinks = {
                website: WeblinkUtil.concatWithProtocol(website),
                bookingLink: WeblinkUtil.concatWithProtocol(bookingLink),
                vrTour: mapVrTour(),
                weblinksItems: mapWebLinksItem()
            }
            await CompanyWeblinksService.update(weblinks)
            const company = await CompanyService.read(companyId as string);
            dispatch(companyActions.setCompany(company));

            setSaving(false);
            cancel();
        } catch (e: any) {
            setSaving(false)
            console.log(e);
            setErrorText('Fehler bei Speicherung ' + e.response?.data);
        }
    }

    function validate() {
        if (!FormUtil.bothOrNothing(vrTourImage, vrTourUrl)) {
            setErrorText("VR Tour benötigt ein Bild und eine gültige Url");
            return false;
        }
        if (!FormUtil.needsOther(link1Url, link1Code)) {
            setErrorText("Link 1 benötigt einen Type und eine gültige Url");
            return false;
        }
        if (!FormUtil.needsOther(link2Url, link2Code)) {
            setErrorText("Link 2 benötigt einen Typ und eine gültige Url");
            return false;
        }
        if (!FormUtil.needsOther(link3Url, link3Code)) {
            setErrorText("Link 3 benötigt einen Typ und eine gültige Url");
            return false;
        }
        return true;
    }

    function onWebsiteChanged(website: string) {
        setErrorText('');
        setWebsite(website);
    }

    function onVrTourImageChanged(image: string) {
        setErrorText('');
        setVrTourImage(image);
    }

    function onVrTourUrlChanged(vrTourUrl: string) {
        setErrorText('');
        setVrTourUrl(vrTourUrl);
    }

    function onLink1CodeChanged(code: string) {
        setErrorText('');
        setLink1Code(code);
    }

    function onLink1UrlChanged(url: string) {
        setErrorText('');
        setLink1Url(url);
    }

    function onLink2CodeChanged(code: string) {
        setErrorText('');
        setLink2Code(code);
    }

    function onLink2UrlChanged(url: string) {
        setErrorText('');
        setLink2Url(url);
    }

    function onLink3CodeChanged(code: string) {
        setErrorText('');
        setLink3Code(code);
    }

    function onLink3UrlChanged(url: string) {
        setErrorText('');
        setLink3Url(url);
    }

    function onTourSupport() {
        window.open("https://www.onra.ch");
    }

    return <>
        <Modal
            show={true}
            backdrop={true}
            onHide={cancel}
            size={'lg'}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>Weblinks bearbeiten</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'me-edit-company-weblinks'}>
                    <InfoListBox iconPlacement={'top-right'} texte={[
                        'Hier kannst du Weblinks für deine Follower festlegen.',
                        'Erfasse deine Webseite, oder beliebige weitere Links sind möglich.',
                        'Der Typ des Links, beinflusst die Darstellung und Text in der Me App',
                        'VR Tour? Gerne unterstützen wird dich, melde dich einfach bei uns.'
                    ]}>
                        <Button variant="outline-secondary"
                                onClick={onTourSupport}>{'Tour anfragen >'}
                        </Button>
                    </InfoListBox>

                    <div className={'me-margin-lg-bottom'}>
                        <Label label={'Webseite'} icon={'globe'}/>
                        <ExternalLink value={website} onChanged={onWebsiteChanged}/>
                    </div>

                    <div className={'me-margin-lg-bottom'}>
                        <Label label={'Buchungsseite (Achtung: Bitte Link 1-3 anstelle verwenden)'}
                               icon={'calendar-alt'}/>
                        <div>{bookingLink}</div>
                    </div>

                    <div className={'me-edit-company-weblinks-vr-tour'}>
                        <Label label={'VR-Tour'} icon={'vr-cardboard'}/>
                        <ExternalLink value={vrTourUrl} onChanged={onVrTourUrlChanged}/>
                        <div className={'me-margin-sm-top me-small'}>Vorschau-Bild um Tour starten...</div>
                        <ImageSelector
                            imageMaxSizeMB={0.5}
                            defaultImage={vrTourImage}
                            imageSelected={onVrTourImageChanged}
                        />

                    </div>

                    <div className={'me-margin-lg-bottom'}>
                        <Label label={'Link 1'} icon={'plus'}/>
                        <Dropdown
                            showRequired={false}
                            value={link1Code}
                            values={EXTRA_LINK_TYPES}
                            onSelected={onLink1CodeChanged}
                        />
                        <ExternalLink value={link1Url} onChanged={onLink1UrlChanged}/>
                    </div>

                    <div className={'me-margin-lg-bottom'}>
                        <Label label={'Link 2'} icon={'plus'}/>
                        <Dropdown
                            showRequired={false}
                            value={link2Code}
                            values={EXTRA_LINK_TYPES}
                            onSelected={onLink2CodeChanged}
                        />
                        <ExternalLink value={link2Url} onChanged={onLink2UrlChanged}/>
                    </div>

                    <div className={'me-margin-lg-bottom'}>
                        <Label label={'Link 3'} icon={'plus'}/>
                        <Dropdown
                            showRequired={false}
                            value={link3Code}
                            values={EXTRA_LINK_TYPES}
                            onSelected={onLink3CodeChanged}
                        />
                        <ExternalLink value={link3Url} onChanged={onLink3UrlChanged}/>
                    </div>

                </div>
                {errorText && <Alert variant='danger'>{errorText}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>
}

export default EditCompanyAbout;