import './ImageSelector.scss';

import React, {useEffect, useState} from "react";
import Image from "react-bootstrap/Image";
import IconButton from "../icon-button/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert} from "react-bootstrap";

interface ImageSelectorProps {
    defaultImage?: string;
    imageSelected: (image: string) => void;
    imageMaxSizeMB?: number;
    editDisabled?: boolean;
}

const DEFAULT_MAX_SIZE = 100000;

const ImageSelector = (props: ImageSelectorProps) => {

    const [error, setError] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        if (props.defaultImage) {
            setImage(props.defaultImage);
        }
    }, [props.defaultImage])


    function onInputChanged(event: any) {
        setError('');
        const file = event.target.files[0];
        event.target.value = null; // clear for delete and choose same image again (change)
        if (!file) {
            return;
        }
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            setError(`Format muss JPEG oder PNG sein.`);
            clearImage();
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const image = reader.result as string;
            imageLoaded(file, image);
        }
        reader.onerror = error => {
            clearImage();
            setError('Fehler: ' + error);
        }
    }


    function imageLoaded(file: any, image: string) {
        const maxFileSize = caculateMaxSize();
        if (file.size > maxFileSize) {
            let img = document.createElement('img');
            img.src = image;
            img.onload = () => {
                resizeImage(img, file, maxFileSize);
            }
        } else {
            setImage(image);
            props.imageSelected(image);
        }
    }

    function resizeImage(img: HTMLImageElement, file: any, maxFileSize: number) {
        const max_dimension = calculateMaxDimension(maxFileSize);
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > max_dimension) {
                height *= max_dimension / width;
                width = max_dimension;
            }
        } else {
            if (height > max_dimension) {
                width *= max_dimension / height;
                height = max_dimension;
            }
        }
        canvas.width = width;
        canvas.height = height;

        ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);
        const extension = file.name.split('.').pop();
        const type = "image/" + extension;
        const resizedImage = canvas.toDataURL(type)
        setImage(resizedImage);
        props.imageSelected(resizedImage);
    }

    function calculateMaxDimension(maxFileSize: number) {
        // Smalles size
        if (maxFileSize <= 100000) {
            return 256;
        }
        // 0.5MB
        if (maxFileSize <= 500000) {
            return 512;
        }
        // 1 MB max size in backend
        return 720;
    }

    function caculateMaxSize() {
        if (props.imageMaxSizeMB) {
            return props.imageMaxSizeMB * 1000000;
        }
        return DEFAULT_MAX_SIZE;
    }

    function clearImage() {
        setImage('');
        props.imageSelected('');
    }

    return (
        <div className={`me-image-selector-container ${props.editDisabled ? 'editDisabled' : ''}`}>
            {image && <Image src={image} alt='' className={'me-file-selector-image'}></Image>}
            {!image &&
                <FontAwesomeIcon className={`me-image-selector-edit`} icon='image'/>
            }
            {image && !props.editDisabled &&
                <div className={'me-image-selector-delete'}>
                    <IconButton className={'red-hover'} iconName={'trash-can'} onClick={clearImage}/>
                </div>
            }
            <input
                type="file" accept="image/jpeg, image/png"
                className={`${props.editDisabled ? 'editDisabled' : ''}`}
                onChange={onInputChanged}>
            </input>
            {error && <Alert className='me-image-selector-error' variant='danger'>
                {error}
            </Alert>
            }
        </div>
    )

};


export default ImageSelector;
