import './CreateAnswersList.scss';

import {SURVEY_CONST, SurveyAnswer, SurveyQuestion} from "../../../data/survey/Survey";
import IconButton from "../../../ui/icon-button/IconButton";
import {Form} from "react-bootstrap";
import React, {useState} from "react";

export interface CreateQuestionAnswersFormProps {
    question: SurveyQuestion;
    onAnswersChanged: (answers: SurveyAnswer[]) => void;
}

const CreateAnswersList = (props: CreateQuestionAnswersFormProps) => {

    const [answers, setAnswers] = useState(props.question.answers);
    const [newAnswerText, setNewAnswerText] = useState('');

    function onNewAnswer(e: any) {
        setNewAnswerText(e.target.value)
    }

    function onAddNewAnswer(e: any) {
        e.stopPropagation();
        if (newAnswerText.length > 0) {
            answers.push({
                text: newAnswerText
            });
        }
        setNewAnswerText('')
        props.onAnswersChanged(answers);
    }

    function onCancelNewAnswer(e: any) {
        e.stopPropagation();
        setNewAnswerText('');
    }

    function moveUp(answer: SurveyAnswer) {
        const fromIndex = answers.map(a => a.text).indexOf(answer.text);
        const movedAnswers = answers.filter(a => a.text !== answer.text);
        const newIndex = fromIndex - 1;
        movedAnswers.splice(newIndex, 0, answer)
        setAnswers(movedAnswers);
        props.onAnswersChanged(movedAnswers);

    }

    function deleteAnswer(answer: SurveyAnswer) {
        const modifiedAnswers = answers.filter(a => a.text !== answer.text);
        setAnswers(modifiedAnswers);
        props.onAnswersChanged(modifiedAnswers);
    }

    const moreAnswers = answers.length <= SURVEY_CONST.MAX_ANSWERS;

    return (
        <div className='me-survey-answer-create-list'>
            <div>
                {answers.map(answer => {
                    const allowUp = answers.map(a => a.text).indexOf(answer.text) > 0;
                    return <div key={answer.text} className='me-create-survey-answer'>
                        <div className='me-survey-answer-text'>{answer.text}</div>
                        {allowUp && <IconButton iconName={'arrow-up'}
                                                className={'no-border small'}
                                                onClick={() => moveUp(answer)}/>}
                        <IconButton iconName={'trash-can'} className={'red-hover no-border small'}
                                    onClick={() => deleteAnswer(answer)}/>
                    </div>
                })}
            </div>
            {moreAnswers && <>
                <Form.Group className="mb-3">
                    <Form.Control
                        className='me-new-answer-input'
                        value={newAnswerText}
                        isValid={true}
                        placeholder={'Antwortmöglichkeit erfassen ...'}
                        onChange={onNewAnswer}/>
                </Form.Group>
                <div className='footer'>
                    <IconButton
                        className={'green-hover'}
                        invisible={newAnswerText.length === 0}
                        iconName={'check'}
                        onClick={e => onAddNewAnswer(e)}/>
                    <IconButton
                        className={'red-hover'}
                        marginLeft={5}
                        invisible={newAnswerText.length === 0}
                        iconName={'xmark'}
                        onClick={e => onCancelNewAnswer(e)}/>
                </div>
            </>}
        </div>
    )

}

export default CreateAnswersList;