import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import {Address} from "../../../../../data/address/Address";
import ProfileFormGroup from "../../../profil-form-group/ProfileFormGroup";

export interface GemeindeAddressFormProps {
    address?: Address;
    onChanged: (address: Address) => void;
}

const GemeindeAddressForm = (props: GemeindeAddressFormProps) => {

    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState<number | undefined>(undefined);
    const [city, setCity] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const formRef = useRef(null);

    useEffect(() => {
        if (props.address) {
            setStreet(props.address.street);
            setStreetNumber(props.address.streetNumber);
            setZip(props.address.zip);
            setCity(props.address.city);
            setLatitude(props.address.coordinate?.latitude as string);
            setLongitude(props.address.coordinate?.longitude  as string);
        }
    }, []);

    useEffect(() => {
        props.onChanged(mapFromState());
    }, [street, streetNumber, zip, city, latitude, longitude]);

    function mapFromState(): Address {
        return {
            street: street ? street : '',
            streetNumber: streetNumber ? streetNumber : '',
            zip: zip ? zip : 0,
            city: city ? city : '',
            coordinate: {
                latitude: latitude ? latitude : '',
                longitude: longitude ? longitude : ''
            }
        }
    }

    return (
        <>
            <ProfileFormGroup label={'Adresse'} icon={'house-flag'}/>
            <Form ref={formRef}>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='street'
                            placeholder={'Strasse*'}
                            onChange={(event) => setStreet(event.target.value)}
                            value={street}
                            required>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='streetNumber'
                            placeholder={'Nr.'}
                            onChange={(event) => setStreetNumber(event.target.value)}
                            value={streetNumber}>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='zip'
                            type={'number'}
                            placeholder={'Plz*'}
                            onChange={(event) => setZip(parseInt(event.target.value))}
                            value={zip ?? ""}
                            required>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='city'
                            placeholder={'Ort*'}
                            onChange={(event) => setCity(event.target.value)}
                            value={city}
                            required>
                        </Form.Control>
                    </Form.Group>

                </Row>
                <Row className={'me-margin-md-top'}>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='latitude'
                            placeholder={'Latitude*'}
                            onChange={(event) => setLatitude(event.target.value)}
                            value={latitude}
                            required>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='longitude'
                            placeholder={'Longitude*'}
                            onChange={(event) => setLongitude(event.target.value)}
                            value={longitude}
                            required>
                        </Form.Control>
                    </Form.Group>
                </Row>
            </Form>
        </>
    )

}

export default GemeindeAddressForm;