import {DateTime} from "../base/DateTime";
import {Visibility} from "../base/Visibility";

export interface AddEvent {
    title: string;
    subtitle: string;
    description: string;
    visibility: Visibility;
    layout: EventLayout;
    action: EventAction;
    invitationList: string[];
    dates: DateTime[];
}

export interface Event {
    eventId: string;
    companyId: string;
    title: string;
    subtitle: string;
    description: string;
    visibility: Visibility;
    state: string;
    dates: DateTime[];
    action: EventAction;
    created: string;
    layout: EventLayout;
    comments: EventComment[];
    invitationList: string[];
    participants: EventParticipant[];
}

export interface EventAction {
    type: EventActionType,
    externalLink: string,
    externalLinkLabelCode: string,
}

export interface EventLayout {
    image: string;
    imagePosition: ImagePosition;
    backgroundColor: string;
    showTitle: boolean;
    titleColor: string;
    titleFontSize: string;
    showSubtitle: boolean;
    subtitleColor: string;
    subtitleFontSize: string;
    showDates: boolean;
    datesColor: string;
    datesFontSize: string;
}

export interface EventParticipant {
    clientId: string;
    created: string;
}

export interface EventComment {
    commentId: string;
    message: string;
    userId: string;
    userName: string;
    parentCommentId: string;
    created: string;
}


export enum ImagePosition {
    COVER = 'COVER',
    CONTAIN = 'CONTAIN',
    TOP = 'TOP'
}

export function emptyEventLayout() {
    return {
        image: '',
        imagePosition: ImagePosition.COVER,
        backgroundColor: '',
        showTitle: true,
        titleColor: '',
        titleFontSize: '20',
        showSubtitle: true,
        subtitleColor: '',
        subtitleFontSize: '14',
        showDates: true,
        datesColor: '',
        datesFontSize: '14',
    }
}

export enum EventState {
    CREATED = 'CREATED',
    ACTIVATED = 'ACTIVATED',
}

export enum EventActionType {
    NO_ACTION = 'NO_ACTION',
    REGISTRATION_ME = 'REGISTRATION_ME',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
}
