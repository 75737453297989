import './List.scss';

import React, {useEffect, useState} from "react";
import {ListGroup} from "react-bootstrap";

export interface ListValue {
    code: string,
    label: string
}

interface ListProps {
    value: string;
    values: ListValue[];
    onSelected: (code: string) => void;
    maxHeight?: string;
}


const List = (props: ListProps) => {

    const [selectedCode, setSelectedCode] = useState('0');

    useEffect(() => {
        if (props.value) {
            setSelectedCode(props.value)
        }
    }, [])

    function onItemClicked(code: string) {
        setSelectedCode(code);
        props.onSelected(code);
    }

    const customStyle: any = {
        maxHeight: '200px',
        overflow: 'auto'
    };
    if (props.maxHeight) {
        customStyle.maxHeight = props.maxHeight;
        customStyle.overflow = 'auto';
    }

    return (
        <ListGroup style={customStyle}>
            {props.values.map(value => {
                return <ListGroup.Item
                    key={value.code}
                    active={selectedCode === value.code}
                    onClick={() => onItemClicked(value.code)}>{value.label}
                </ListGroup.Item>
            })}
        </ListGroup>
    );


}

export default List;