import './AccountLogo.scss'
import ImageSelector from "../../../ui/image-selector/ImageSelector";
import React from "react";

export interface CompanyLogoProps {
    image: string;
    imageChanged: (image: string) => void;
}

const AccountLogo = (props: CompanyLogoProps) => {

    return (

        <div className='me-account-logo-container'>
            <ImageSelector
                defaultImage={props?.image}
                imageSelected={props.imageChanged}
            />
        </div>
    )
}


export default AccountLogo;