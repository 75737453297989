import './ReplyEventComment.scss';
import {Form} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {EventService} from "../../../../data/event/Event.service";
import Spinner from "../../../../ui/spinner/Spinner";
import IconButton from "../../../../ui/icon-button/IconButton";

export interface ReplyEventCommentProps {
    eventId: string;
    companyId: string;
    parentCommentId: string;
    reply: () => void;
}

const ReplyEventComment = (props: ReplyEventCommentProps) => {

    const [focus, setFocus] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState('');
    const [footerRef, setFooterRef] = useState();

    let ref = useRef(null);
    if (!footerRef) {
        // @ts-ignore
        setFooterRef(ref);
    }

    function changeMessage(e: any) {
        setMessage(e.target.value)
    }

    function onReply() {
        setSaving(true);
        EventService.addComment(props.companyId, props.eventId, message, props.parentCommentId).then(() => {
            setSaving(false);
            setFocus(false);
            setMessage('');
            props.reply();
        })
    }

    function onCancel() {
        setFocus(false);
        setMessage('');
    }

    function onMessageFocus() {
        setFocus(true);
        setTimeout(() => {
            // @ts-ignore
            footerRef?.current?.scrollIntoView({behavior: 'smooth'})
        })
    }

    return (
        <div className={'me-reply-event-comment-container'}>
            <Form.Group className={'comment-input'}>
                <Form.Control
                    className={focus ? 'expanded' : ''}
                    as="textarea" rows={5}
                    value={message}
                    placeholder={'Antwort erfassen ...'}
                    onFocus={onMessageFocus}
                    onChange={changeMessage}/>
            </Form.Group>
            {saving && <Spinner small marginTop={10}></Spinner>}
            {focus && !saving && <div ref={footerRef} className={'footer'}>
                <IconButton
                    className={'green-hover'}
                    disabled={message.length === 0}
                    iconName={'check'}
                    onClick={onReply}/>
                <IconButton
                    className={'red-hover'}
                    marginLeft={5}
                    iconName={'xmark'}
                    onClick={onCancel}/>
            </div>}
        </div>
    )

}


export default ReplyEventComment;

