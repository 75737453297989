import axiosInstance from "../../AxiosInterceptor";
import {AddSurvey, Survey, SurveyResult} from "./Survey";


export class SurveyService {


    public static update(survey: Survey): Promise<void> {
        return axiosInstance.put('companies/surveys/', survey);
    }

    public static add(addSurvey: AddSurvey): Promise<void> {
        return axiosInstance.post('companies/surveys/', addSurvey);
    }

    public static async search(companyId: string): Promise<Survey[]> {
        let response = await axiosInstance.get(`companies/${companyId}/surveys/`);
        return response.data.map(SurveyService.mapSurvey);
    }

    public static async deleteSurvey(companyId: string, surveyId: string) {
        return await axiosInstance.delete(`companies/${companyId}/surveys/${surveyId}`);
    }


    public static async activate(companyId: string, surveyId: string) {
        return await axiosInstance.post(`companies/${companyId}/surveys/${surveyId}/activate`);
    }

    public static async readResults(companyId: string, surveyId: string) {
        let response = await axiosInstance.get(`companies/${companyId}/surveys/${surveyId}/results`);
        return SurveyService.mapResult(response.data);
    }

    static mapSurvey(data: any): Survey {
        return {
            surveyId: data.surveyId,
            companyId: data.companyId,
            state: data.state,
            visibility: data.visibility,
            invitationList: data.invitationList,
            enddate: data.enddate,
            intro: data.intro,
            questions: data.questions,
            confirm: data.confirm,
            created: data.created
        }
    }

    static mapResult(data: any): SurveyResult {
        return {
            questionsResults: data.questionsResults.map((questionResultData: any) => {
                return {
                    question: questionResultData.question,
                    answersResults: questionResultData.answersResults.map((answerResultData: any) => {
                        return {
                            text: answerResultData.text,
                            count: answerResultData.count,
                            percent: answerResultData.percent,
                        }
                    })
                }
            })
        }
    }


}