import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Event} from "../data/event/Event";

export interface EventsState {
    loading: boolean;
    events: Event[];
}

const initialState: EventsState = {
    loading: false,
    events: []
}

const eventsSlice = createSlice({
    name: 'events',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<Event[]>) {
            state.events = action.payload;
        },
        replaceOne(state, action: PayloadAction<Event>) {
            const events = state.events.filter(e => e.eventId !== action.payload.eventId);
            events.push(action.payload);
            state.events = events;
        },
    }
})

export const eventsActions = eventsSlice.actions;
export default eventsSlice.reducer;
