import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Account} from "../data/account/Account";

export interface AccountState {
    show: boolean,
    data: Account | null,
}

const initialState: AccountState = {
    show: false,
    data: null
}

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        showAccount(state) {
            state.show = true;
        },
        hideAccount(state) {
            state.show = false;
        },
        setAccount(state, action: PayloadAction<Account>) {
            state.data = action.payload;
        }
    }
})

export const accountActions = accountSlice.actions;
export default accountSlice.reducer;