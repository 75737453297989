import {Form} from "react-bootstrap";
import Dropdown from "../../../ui/dropdown/Dropdown";
import {EventAction, EventActionType} from "../../../data/event/Event";
import ExternalLink from "../../../ui/link/ExternalLink";
import List, {ListValue} from "../../../ui/list/List";
import React, {useEffect, useState} from "react";
import {EventUtil} from "../../../data/event/EventUtil";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import InfoListBox from "../../../ui/info-box/info-list-box";

export interface CreateEventActionFormProps {
    formValidated: boolean;
    value: EventAction | null;
    onChange: (action: EventAction) => void;
}

const CreateEventActionForm = (props: CreateEventActionFormProps) => {

    const company = useSelector((state: RootState) => state.company?.data);

    const [type, setType] = useState<string>('');
    const [externalLink, setExternalLink] = useState<string>('');
    const [externalLinkLabelCode, setExternalLinkLabelCode] = useState<string>('BOOKING');

    const actionTypes = EventUtil.actionTypes();
    const externalLinkLabels: ListValue[] = EventUtil.externalLinkLabels();


    useEffect(() => {
        if (props.value) {
            setType(props.value.type);
            setExternalLink(props.value.externalLink);
            setExternalLinkLabelCode(props.value.externalLinkLabelCode);
        }
    }, [])

    useEffect(() => {
        modelChanged()
    }, [
        type,
        externalLink,
        externalLinkLabelCode
    ])

    function modelChanged() {
        props.onChange({
            type: type as EventActionType,
            externalLink: externalLink,
            externalLinkLabelCode: externalLinkLabelCode,
        })
    }

    function onEventActionTypeChanged(code: string) {
        setType(code);
        if (code === EventActionType.EXTERNAL_LINK && company?.weblinks?.bookingLink) {
            setExternalLink(company?.weblinks?.bookingLink);
        }
    }

    function onEventActionLinkChanged(link: string) {
        setExternalLink(link);
    }

    function isExternalLink() {
        return type === EventActionType.EXTERNAL_LINK;
    }

    function isNoAction() {
        return type === EventActionType.NO_ACTION;
    }

    function isRegistration() {
        return type === EventActionType.REGISTRATION_ME;
    }

    return (
        <Form id='createEventActionsForm'
              className='me-create-event-form'
              noValidate
              validated={props.formValidated}>
            <div>
                <Dropdown
                    showRequired={true}
                    value={type}
                    values={actionTypes}
                    onSelected={onEventActionTypeChanged}
                />
                {isExternalLink() && <>
                    <div className={'mb-3 mt-3'}>
                        <Form.Label>Link</Form.Label>
                        <ExternalLink
                            value={externalLink}
                            onChanged={onEventActionLinkChanged}/>
                    </div>
                    <div className={'mb-3 mt-3'}>
                        <Form.Label>Anzeigetext für den Button</Form.Label>
                        <List
                            values={externalLinkLabels}
                            value={externalLinkLabelCode}
                            onSelected={code => setExternalLinkLabelCode(code)}
                        />
                    </div>
                    <InfoListBox texte={[
                        'Im Event wird der obige Link hinterlegt.',
                        'Deine Follower können den Button anklicken und die Aktion ausführen.',
                        'Aus der App kann dadurch eine externe Seite aufgerufen werden.'
                    ]}/>
                </>}
                {isNoAction() && <InfoListBox listContainerStyle={{marginTop: 20}} texte={[
                    'Im Event wird für deine Follower keine weitere Aktion (z. B. Anmeldelink) angezeigt.',
                    'Der Event ist rein informativ.',
                    'Kommentare sind jedoch weiterhin möglich.'
                ]}/>}
                {isRegistration() && <InfoListBox listContainerStyle={{marginTop: 20}} texte={[
                    'Im Event haben deine Follower die Möglichkeit, sich anzumelden.',
                    'Anmeldungen gehen über die Me-Plattform ein.',
                    'In der Event-Übersicht sind die Anmeldungen für dich ersichtlich.'
                ]}/>}
            </div>
        </Form>
    )

}

export default CreateEventActionForm;