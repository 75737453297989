export class FormUtil {

    static bothOrNothing(field1: string, field2: string): boolean {
        if (field1 && field2) {
            return true;
        }
        return !field1 && !field2;
    }

    static needsOther(field1: string, field2: string): boolean {
        return !(field1 && !field2);

    }

}