export enum PostState {
    CREATED = 'CREATED',
    ACTIVATED = 'ACTIVATED',
}

export interface Post {
    postId: string;
    title: string;
    text: string;
    image: string;
    linkUrl: string;
    state: PostState;
    showAt: string;
    likes: PostLike[];
    created?: string;
}

export interface PostLike {
    clientId: string;
    created: string;
}